import React, { Component } from "react";
import { connect } from "react-redux";
import RichTextArea from "@components/form/richtext-area-lazy";
import ImageSelect from "@components/form/image-select";
import _ from "lodash";
import { showToast, createModal } from "@actions/ui";
import { Intent } from "@blueprintjs/core";
import { updateMe } from "@actions/dashboard/update-me";
import { toBase64 } from "@utils/toBase64";
import { getActiveLocations } from "@selectors/filters";
import InputField from "@components/fields/InputField";
import SelectField from "@components/fields/SelectField";
import TextAreaField from "@components/fields/TextAreaField";
import { AccountEdit } from "../account";
import extractContent from "@utils/extract-content";
import { navigate } from "gatsby";
import { removeModal } from "@actions/ui";

class ProfileEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      touched: {},
    };
  }

  handleChange = (fieldKey, value) => {
    if (fieldKey === "bio") {
      const content = extractContent(value);
      if (!content) {
        value = "";
      }
    }

    this.setState((state) => {
      const formData = { ...state.formData, [fieldKey]: value };
      const touched = { ...state.touched, [fieldKey]: true };
      return { formData, touched };
    });
  };

  onImageChange = async (obj) => {
    const key = obj.name;
    let value = obj.value;
    try {
      value = (value ? await toBase64(value) : "").replace(/.+base64,/, "");
    } catch (e) {
      value = "";
    } finally {
      this.setState((state) => {
        const formData = { ...state.formData, [key]: value };
        return { formData };
      });
    }
  };

  updateMe = async (e) => {
    e.preventDefault();
    const {
      me: { rnRoles = [] },
      profile,
      updateMe,
      showToastError,
      showToastSuccess,
      removeModal,
    } = this.props;
    const { formData } = this.state;
    try {
      await updateMe(formData);
      showToastSuccess("Success!");
      const username = formData.username || profile.username;
      const url = rnRoles.includes("curator")
        ? `/u/${username}`
        : `/u/${username}`;
      removeModal("profile_edit");
      setTimeout(() => navigate(url), 1000);
    } catch (error) {
      const { isAxiosError, response } = error;
      let error_message = "Something Wrong";
      if (isAxiosError && response && response.status === 422) {
        const { data: { message = "" } = {} } = response;
        if (message) {
          error_message = message;
        }
      }
      showToastError(error_message);
    }
  };

  handleAccountButtonClick = () => {
    const { createModal, close_others } = this.props;
    const modal = {
      id: "account_edit",
      Component: AccountEdit,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };
    createModal(modal, close_others);
  };

  renderImageText = () => (
    <div className="image-text-container">
      <p className="image-text-header">Profile Image</p>
      <ul className="image-text-list">
        <li className="image-text-list-item">{"Square"}</li>
        <li className="image-text-list-item">{"300 x 300 px min"}</li>
      </ul>

      {this.props.required && (
        <span className="form__required-indicator">*</span>
      )}
    </div>
  );

  renderScreenNameAndUserNameInputs = (
    formData,
    screenName,
    username,
    touched
  ) => (
    <React.Fragment>
      <InputField
        required={true}
        maxLength={30}
        label="Screen Name"
        name="screenName"
        value={formData.screenName}
        initialValue={screenName}
        touched={touched.screenName}
        handleChange={this.handleChange}
      />
      <InputField
        required={false}
        maxLength={30}
        label="URL"
        name="username"
        prefixWidth={"124px"}
        prefix="URL | restlessnites.com/u/"
        value={formData.username}
        initialValue={username}
        touched={touched.username}
        handleChange={this.handleChange}
      />
    </React.Fragment>
  );

  render() {
    const {
      profile,
      regions = [],
      me: { rnRoles = [] },
      browser,
    } = this.props;
    const { formData, touched } = this.state;
    const { username, screenName, bio, shortBio, image, region } = profile;
    const isMobile = !browser.greaterThan.mobile;
    const isTablet = !isMobile && !browser.greaterThan.large;

    return (
      <form onSubmit={this.updateMe}>
        <span>
          <div className="form__profile-details">
            <div className="container-flex-item">
              <div className="top-part-container">
                <ImageSelect
                  editable={true}
                  required={false}
                  imageUrl={formData.image}
                  initialValue={image}
                  touched={touched.image}
                  label="Profile Image"
                  name="image"
                  handleChange={this.onImageChange}
                />
                <div className="image-left-part-container">
                  {this.renderImageText()}
                  {isTablet &&
                    this.renderScreenNameAndUserNameInputs(
                      formData,
                      screenName,
                      username,
                      touched
                    )}
                </div>
              </div>
              {!isTablet &&
                this.renderScreenNameAndUserNameInputs(
                  formData,
                  screenName,
                  username,
                  touched
                )}
              {rnRoles.includes("curator") && (
                <SelectField
                  required={false}
                  label="Region"
                  name="region"
                  editable={true}
                  options={regions}
                  defaultValue={
                    { value: region, label: _.startCase(region) } || {
                      value: "select-region",
                      label: "Select Region",
                    }
                  }
                  value={_.startCase(formData.region)}
                  touched={touched.region}
                  handleChange={this.handleChange}
                />
              )}
              <TextAreaField
                maxLength={150}
                label="Short Bio (max 150 characters)"
                name="shortBio"
                value={formData.shortBio}
                initialValue={shortBio}
                touched={touched.shortBio}
                handleChange={this.handleChange}
                inputContainerClass={"profile-short-bio-textarea"}
              />
            </div>
            <div className="container-flex-item">
              <RichTextArea
                editable={true}
                maxLength={500}
                label="Long Bio"
                name="bio"
                placeholder={"Long Bio (links allowed)"}
                initialValue={bio}
                value={formData.bio}
                touched={touched.username}
                handleChange={({ name, value }) =>
                  this.handleChange(name, value)
                }
                className={rnRoles.includes("curator") ? "large" : ""}
              />
              <div className="form_button-container">
                <button
                  type="submit"
                  className={`btn ${!isTablet ? "" : "half"}`}
                >
                  {"SUBMIT & VIEW PAGE"}
                </button>
                <button
                  type="button"
                  className={`btn configure-edit-button no-back small-text  ${
                    isTablet || isMobile ? " m-l-20 auto-size " : "with-border"
                  }`}
                  onClick={this.handleAccountButtonClick}
                >
                  {"EDIT ACCOUNT INFORMATION "}
                </button>
              </div>
            </div>
          </div>
        </span>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.me.data,
    regions: getActiveLocations(state),
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    updateMe: (data) => {
      return dispatch(updateMe(data));
    },
    showToastError: (message) => {
      return dispatch(showToast(message));
    },
    showToastSuccess: (message) => {
      return dispatch(showToast(message, Intent.SUCCESS));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditForm);
