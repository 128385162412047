import { createSelector } from "reselect";
import { get, find } from "lodash";

export const getFilters = (state) => get(state, "eventFilters", null);

// this will return all regions in the system. Only use for submitting events
export const getAllLocations = createSelector(getFilters, (filters) =>
  get(filters, "location", null)
);

// most common usage. Will filter out private regions
export const getActiveLocations = createSelector(
  getAllLocations,
  (locations) => {
    if (!locations) {
      return locations;
    }
    return locations.filter((loc) => !get(loc, "private", false));
  }
);

export const currentLocation = createSelector(getAllLocations, (locations) =>
  find(locations, { selected: true })
);
