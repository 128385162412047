function deg2Rad(deg) {
  return (deg * Math.PI) / 180;
}

function pythagorasEquirectangular(lat1, lon1, lat2, lon2) {
  const lat1_rad = deg2Rad(lat1);
  const lat2_rad = deg2Rad(lat2);
  const lon1_rad = deg2Rad(lon1);
  const lon2_rad = deg2Rad(lon2);
  const R = 6371;
  const x = (lon2_rad - lon1_rad) * Math.cos((lat1_rad + lat2_rad) / 2);
  const y = lat2_rad - lat1_rad;
  const d = Math.sqrt(x * x + y * y) * R;
  return d;
}

export function nearestCity(latitude, longitude, cities) {
  let mindif = 99999;
  let closest = 0;

  for (let index = 0; index < cities.length; ++index) {
    const dif = pythagorasEquirectangular(
      latitude,
      longitude,
      cities[index][1],
      cities[index][2]
    );
    if (dif < mindif) {
      closest = index;
      mindif = dif;
    }
  }

  return cities[closest];
}
