import React, { Component } from "react";
import { Link } from "gatsby";
import AccountWidget from "../../containers/global/account-widget";
import LoginModal from "../../containers/auth/login_modal";
import RegistrationModal from "../../containers/auth/registration_modal";
import ForgotPasswordModal from "../../containers/auth/forgot_password_modal";
import MobileMenuCloseIcon from "@assets/svg/svgui/mobile-menu-close-icon.svg";
import { Transition } from "react-transition-group";
import NewsLetterSignupModule from "@components/modules/common/newsletter-signup-module";

const defaultStyle = {
  transition: `opacity ${300}ms ease-in-out, width ${300}ms ease-in-out`,
  opacity: 0,
  width: "0%",
};

const transitionStyles = {
  entering: { opacity: 1, width: "100%" },
  entered: { opacity: 1, width: "100%" },
  exiting: { opacity: 0, width: "0%" },
  exited: { opacity: 0, width: "0%" },
};

class MobileMenuOverlay extends Component {
  handleCreateAccount = () => {
    const { createModal, removeModal, toggleNav } = this.props;

    const modal = {
      id: "registration_modal",
      Component: () => (
        <div
          style={{
            maxWidth: "100%",
            height: "inherit",
            display: "inline-flex",
            width: "748px",
          }}
        >
          <RegistrationModal
            removeModal={removeModal}
            openLoginModal={this.openLoginModal}
            toggleNav={toggleNav}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: true,
    };

    createModal(modal, true);
  };

  handleForgotPassword = () => {
    const { createModal } = this.props;

    const modal = {
      id: "forgot_password_modal",
      Component: () => <ForgotPasswordModal />,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: true,
    };

    createModal(modal, true);
  };

  openLoginModal = () => {
    const { createModal, navOpen, toggleNav, browser, removeModal } =
      this.props;
    const isMobile = browser.lessThan.medium;

    const modal = {
      id: "login_modal",
      Component: () => (
        <div
          style={{
            maxWidth: isMobile ? "100%" : "480px",
            height: "inherit",
            display: "inline-flex",
            maxHeight: 550,
          }}
        >
          <LoginModal
            validateInput={this.validateInput}
            removeModal={removeModal}
            handleCreateAccount={this.handleCreateAccount}
            handleForgotPassword={this.handleForgotPassword}
          />
        </div>
      ),
      shouldCloseOnOverlayClick: false,
      isCloseBtn: true,
    };
    createModal(modal, true);

    if (navOpen) {
      toggleNav();
    }
  };

  render() {
    const { toggleNav, location, loggedIn, auth, navOpen } = this.props;

    return (
      <Transition nodeRef={this.nodeRef} in={this.props.navOpen} timeout={300}>
        {(state) => (
          <div
            ref={this.nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            className="mobile-menu-overlay"
          >
            <div className="mobile-menu-overlay__content">
              <div
                className={`SVGInline mobile-menu-overlay__close ${
                  navOpen ? "" : "hide"
                }`}
                onClick={() => toggleNav()}
              >
                <MobileMenuCloseIcon
                  className="SVGInline-svg mobile-menu-overlay__close-svg"
                  style={{ width: 17, height: 17 }}
                />
              </div>
              <div className="mobile-menu-overlay__scroll-wrapper">
                <nav className="main-nav">
                  <ul className="clearfix">
                    <li className={location === "/events" ? "active" : ""}>
                      <Link onClick={toggleNav} to="/events">
                        Events
                      </Link>
                    </li>
                    <li className={location === "/curators" ? "active" : ""}>
                      <Link onClick={toggleNav} to="/curators">
                        Curators
                      </Link>
                    </li>
                    <li className={location === "/editorial" ? "active" : ""}>
                      <Link onClick={toggleNav} to="/editorial">
                        Editorial
                      </Link>
                    </li>
                    {loggedIn && (
                      <li className={location === "/orders" ? "active" : ""}>
                        <Link onClick={toggleNav} to="/orders">
                          My Tickets
                        </Link>
                      </li>
                    )}
                  </ul>
                </nav>
                <nav className="util-nav">
                  <ul>
                    {!loggedIn && (
                      <li className={location === "/login" ? "active" : ""}>
                        <span aria-hidden={true} onClick={this.openLoginModal}>
                          Login
                        </span>
                      </li>
                    )}
                    {loggedIn && (
                      <li>
                        <AccountWidget auth={auth} isMobile={Boolean(true)} />
                      </li>
                    )}
                  </ul>
                </nav>
                <NewsLetterSignupModule />
              </div>
            </div>
          </div>
        )}
      </Transition>
    );
  }
}

export default MobileMenuOverlay;
