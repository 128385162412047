import validator from "validator";

import {
  // utils
  createValidator,

  // checked functions
  createIsMinChecked,
  createIsMaxChecked,
  minLengthChecked,
  maxLengthChecked,
} from ".";
import { toInt } from "./checkedFunctions";

export const requiredValidator = (error_message = "Required") => {
  return createValidator(
    error_message,
    (v) => !validator.isEmpty(String(v), { ignore_whitespace: true })
  );
};

export const isEmailValidator = (error_message = "Invalid email address") => {
  return createValidator(error_message, validator.isEmail);
};

export const minNumberValidator = (
  error_message = "Value must be greater than or equal to {{min}}",
  min
) => {
  return createValidator(
    error_message.replace("{{min}}", min),
    createIsMinChecked(min)
  );
};

export const maxNumberValidator = (
  error_message = "Value must be less than or equal to {{max}}",
  max
) => {
  return createValidator(
    error_message.replace("{{max}}", max),
    createIsMaxChecked(max)
  );
};

export const minLengthValidator = (
  error_message = "Length must be greater than or equal to {{min}}",
  min
) => {
  return createValidator(
    error_message.replace("{{min}}", min),
    minLengthChecked(min)
  );
};

export const maxLengthValidator = (
  error_message = "Length must be less than or equal to {{max}}",
  max
) => {
  return createValidator(
    error_message.replace("{{max}}", max),
    maxLengthChecked(max)
  );
};

export const emptyArrayValidator = (error_message = "Required") => {
  return createValidator(error_message, (v) => v && v.length);
};

export const intValidator = (error_message = "Invalid") => {
  return createValidator(error_message, (v) => toInt(v) === v);
};

const phoneNumberReg =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export const phoneNumberValidator = (
  error_message = "Invalid phone number"
) => {
  return createValidator(error_message, (v) => phoneNumberReg.test(v));
};
