import { ProfileEditButton } from "../profile";
import { AccountEditButton } from "../account";
import {
  MeLink,
  EventsLink,
  NewEventCreateLink,
  BrandsLink,
  NewBrandCreateLink,
  CreatePageLink,
  ManagePagesLink,
  CreatePostLink,
  ManagePostsLink,
  ImportFbEventLink,
} from "../../components/links";

export const getDashboardItems = (rnRoles = []) => {
  let items = [
    {
      key: "account",
      label: "Account",
      items: [
        {
          key: "manage_account",
          label: "Manage",
          component: AccountEditButton,
        },
      ],
    },
    {
      key: "profile",
      label: "Profile",
      items: [
        {
          key: "manage_profile",
          label: "Manage",
          component: ProfileEditButton,
        },
        {
          key: "view_profile",
          label: "View",
          component: MeLink,
        },
      ],
    },
  ];

  if (
    rnRoles.some(
      Array.prototype.includes.bind(["promoter", "curator", "admin"])
    )
  ) {
    const eventItems = [
      {
        key: "manage_events",
        label: "Manage",
        component: EventsLink,
      },
      {
        key: "create_event",
        label: "Create",
        component: NewEventCreateLink,
      },
    ];

    if (rnRoles.some(Array.prototype.includes.bind(["admin"]))) {
      eventItems.push({
        key: "fb_event_import",
        label: "Import from FB",
        component: ImportFbEventLink,
      });
    }

    items = [
      ...items,
      {
        key: "events",
        label: "Events",
        items: eventItems,
      },
      {
        key: "brands",
        label: "Brands",
        items: [
          {
            key: "manage_brands",
            label: "Manage",
            component: BrandsLink,
          },
          {
            key: "create_brand",
            label: "Create",
            component: NewBrandCreateLink,
          },
        ],
      },
    ];
  }

  /*if (rnRoles.some(Array.prototype.includes.bind(["admin", "curator"]))) {
    items = [
      ...items,
      {
        key: "posts",
        label: "Posts",
        items: [
          {
            key: "manage_posts",
            label: "Manage",
            component: ManagePostsLink,
          },
          {
            key: "submit_post",
            label: "Submit",
            component: CreatePostLink,
          },
        ],
      },
    ];
  }*/

  if (rnRoles.some(Array.prototype.includes.bind(["admin"]))) {
    items = [
      ...items,
      {
        key: "pages",
        label: "Pages",
        items: [
          {
            key: "manage_pages",
            label: "Manage",
            component: ManagePagesLink,
          },
          {
            key: "submit_page",
            label: "Submit",
            component: CreatePageLink,
          },
        ],
      },
    ];
  }

  return items;
};
