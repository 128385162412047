import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { removeModal, createModal, removeAllModal } from "../../actions/ui";
import CloseIcon from "@assets/svg/svgui/mobile-modal-exit.svg";

class ModalServices extends PureComponent {
  componentDidMount() {
    const { removeAllModal } = this.props;
    removeAllModal();
  }

  closeModal = (id) => {
    const { removeModal } = this.props;
    removeModal(id);
  };

  render() {
    const { modals = [], browser } = this.props;
    const isMobile = browser.lessThan.medium;

    return (
      <Fragment>
        {modals.map((modal) => {
          const { id, isCloseBtn = true, Component, ...options } = modal;

          return (
            <Modal
              style={{
                overlay: {
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 12,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.76)",
                  marginTop: isMobile ? 57 : "auto",
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: 0,
                  maxHeight: isMobile ? "100%" : "95%",
                  maxWidth: "100%",
                  borderRadius: 0,
                  border: isMobile ? "none" : "1px solid #FFFFFF",
                  background: "transparent",
                },
              }}
              key={id}
              isOpen={true}
              ariaHideApp={false}
              closeTimeoutMS={150}
              shouldCloseOnOverlayClick={false}
              onRequestClose={() => this.closeModal(id)}
              {...options}
            >
              <div className="modal-main-container">
                {!isMobile && isCloseBtn && (
                  <span
                    className="modal-close"
                    onClick={() => this.closeModal(id)}
                  >
                    &times;
                  </span>
                )}
                {isMobile && isCloseBtn && (
                  <div
                    className="SVGInline mobile-modal-exit"
                    onClick={() => this.closeModal(id)}
                  >
                    <CloseIcon className="SVGInline-svg mobile-modal-exit-svg" />
                  </div>
                )}
                <Component
                  browser={browser}
                  closeModal={() => this.closeModal(id)}
                />
              </div>
            </Modal>
          );
        })}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modals: state.ui.modals.modals,
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    createModal: (modal) => {
      dispatch(createModal(modal));
    },
    removeAllModal: () => {
      dispatch(removeAllModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalServices);
