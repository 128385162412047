import { combineReducers } from "redux";
import { createResponsiveStateReducer } from "redux-responsive";
import events from "./events-data";
import ui from "./ui";
import platform from "./platform";
import eventFilters from "./event-filters";
import eventUsers from "./event-users";
import auth from "./auth";
import user from "./user";
import shared from "./shared";
import checkout from "./checkout";
import tickets from "./tickets";
import search from "./search-data";
import editorial from "./editorial";
import eventsAccess from "./events-access";

const rootReducer = combineReducers({
  auth,
  ui,
  user,
  shared,
  events,
  eventFilters,
  eventUsers,
  checkout,
  tickets,
  search,
  editorial,
  eventsAccess,
  browser: createResponsiveStateReducer(
    {
      mobile: 640,
      mediumSmall: 700,
      medium: 876,
      large: 1024,
    },
    {
      extraFields: () => ({
        width: this?.window?.innerWidth,
        height: this?.window?.innerHeight,
      }),
    }
  ),
  platform,
});

export default rootReducer;
