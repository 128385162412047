import {
  SET_CHECKOUT_DATA,
  CLEAR_CHECKOUT_DATA,
} from "../constants/action-types";

const initialState = {
  checkoutData: {},
  checkoutDataError: false,
  checkoutDataErrorMsg: "",
  status: null,
  checkoutDataLoading: false,
};

export default function checkout(state = initialState, action) {
  switch (action.type) {
    case SET_CHECKOUT_DATA: {
      return Object.assign({}, state, {
        checkoutDataLoading: false,
        checkoutData: action.payload.data,
        status: action.payload.status,
        checkoutDataErrorMsg: action.payload.message,
        checkoutDataError: action.payload.error,
      });
    }
    case CLEAR_CHECKOUT_DATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
