import _ from "lodash";
import update from "immutability-helper";
import {
  AUTH_LOGOUT,
  AUTH_USER_GET_START,
  AUTH_USER_GET_SUCCESS,
  AUTH_USER_GET_ERROR,
  RECOMMEND_EVENT_SUCCESS,
  ME_DATA_UPDATE_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
} from "../constants/action-types";
import store from "store";

const initialState = {
  me: {
    exist: false,
    checked: false,
    loading: false,
    data: null,
  },
};

export default function auth(state = _.cloneDeep(initialState), action) {
  switch (action.type) {
    case AUTH_LOGOUT: {
      store.remove("user_data");
      return _.cloneDeep(initialState);
    }

    case AUTH_USER_GET_START: {
      const updates = {
        me: {
          $merge: {
            loading: true,
          },
        },
      };
      return update(state, updates);
    }

    case AUTH_USER_GET_SUCCESS: {
      const { data } = action.payload;
      const updates = {
        me: {
          $merge: {
            exist: true,
            checked: true,
            loading: false,
            data,
          },
        },
      };
      return update(state, updates);
    }

    case AUTH_USER_GET_ERROR: {
      const updates = {
        me: {
          $merge: {
            exist: false,
            checked: true,
            loading: false,
          },
        },
      };
      return update(state, updates);
    }
    case RECOMMEND_EVENT_SUCCESS: {
      const { recommend, id } = action.payload;
      let recommendedEvents = _.get(state, "me.data.recommendedEvents", []);
      if (recommend) {
        recommendedEvents = _.concat(recommendedEvents, id);
      } else {
        recommendedEvents = _.filter(recommendedEvents, (_id) => _id !== id);
      }

      const updates = {
        me: {
          data: {
            recommendedEvents: {
              $set: recommendedEvents,
            },
          },
        },
      };
      return update(state, updates);
    }
    case UPDATE_ACCOUNT_SUCCESS:
    case ME_DATA_UPDATE_SUCCESS: {
      const { data } = action.payload;
      const updates = {
        me: {
          data: {
            $set: data,
          },
        },
      };
      return update(state, updates);
    }
    default: {
      return state;
    }
  }
}
