import { SET_TICKETS_SUCCESS } from "../constants/action-types";

const initialState = {};

function tickets(state = initialState, action) {
  switch (action.type) {
    case SET_TICKETS_SUCCESS: {
      const { data = [] } = action.payload;
      return [...data];
    }
    default: {
      return state;
    }
  }
}

export default tickets;
