const filters = {
  locations: [
    {
      label: "Los Angeles",
      timezone: "America/Los_Angeles",
      zip: 90012,
      lat: 34.052235,
      lng: -118.243683,
      neighborhoods: [
        "South LA",
        "Long Beach",
        "Hollywood",
        "Desert Cities",
        "Orange County",
        "Pasadena / SGV",
        "Santa Barbara",
        "West Hollywood",
        "NoHo / The Valley",
        "Westside / Beaches",
        "Glendale / Burbank",
        "Downtown / Chinatown",
        "Mid City / Koreatown",
        "Echo Park / Silverlake",
        "Highland Park / NELA",
        "Pomona / Inland Empire",
      ],
      social: {
        fb: "restlessnites",
        ig: "restlessnites",
        twitter: "restlessnites",
      },
    },
    {
      label: "New York",
      timezone: "America/New_York",
      zip: 10036,
      lat: 40.73061,
      lng: -73.935242,
      neighborhoods: [
        "Bronx",
        "Queens",
        "Harlem",
        "Midtown",
        "Upstate",
        "Bed-Stuy",
        "Bushwick",
        "Ridgewood",
        "Greenpoint",
        "Long Island",
        "Williamsburg",
        "Central Park",
        "Staten Island",
        "Upper Manhattan",
        "Lower Manhattan",
        "Long Island City",
        "Downtown Brooklyn",
        "Coney Island / Rockaway",
      ],
      social: {
        fb: "restlessnites.nyc",
        ig: "restlessnyc",
        twitter: "restlessnyc",
      },
      private: false,
    },
    {
      label: "Chicago",
      value: "chicago",
      timezone: "America/Chicago",
      zip: 60602,
      lat: 41.881832,
      lng: -87.623177,
      neighborhoods: [
        "Lakeview",
        "Edgewater",
        "Lincoln Park",
        "Logan Square",
        "Wicker Park",
        "South Loop",
        "Old Town",
        "River North",
        "Gold Coast",
        "Rogers Park",
        "Hyde Park",
        "Lincoln Square",
      ],
      private: false
    },
    {
      label: "Texas",
      value: "austin",
      timezone: "America/Chicago",
      zip: 78701,
      lat: 31,
      lng: -99,
      neighborhoods: [],
      private: false,
    },
    {
      label: "Pacific NW (US/CA)",
      value: "pacific northwest",
      timezone: "America/Los_Angeles",
      zip: 78701,
      lat: 31,
      lng: -99,
      neighborhoods: [],
      private: false,
    },
    {
      label: "SF / Bay Area",
      value: "san francisco",
      timezone: "America/Los_Angeles",
      zip: 94104,
      lat: 37.7749,
      lng: -122.431297,
      neighborhoods: [
        "Berkeley",
        "Temescal",
        "West Oakland",
        "Uptown Oakland",
        "North Bay / Marin",
        "East Bay",
        "South Bay / San Jose",
        "North Beach / Embarcadero",
        "Presidio / Richmond",
        "SOMA",
        "Financial District",
        "Haight-Ashbury / Inner Sunset",
        "Mission Bay / Dogpatch",
        "Sunset / W. Portal",
        "Mission District",
        "Bernal Hts / Noe Valley",
        "Hunters Point",
        "Excelsior / Outer Mission",
      ],
      social: {
        fb: "restlessnites.sf",
        ig: "restlessbayarea",
        twitter: "restlessbayarea",
      },
      private: false,
    },
    {
      label: "San Diego",
      value: "sandiego",
      timezone: "America/Los_Angeles",
      zip: 92105,
      lat: 32.97159688257472,
      lng: -117.16584526792143,
      neighborhoods: [
        "Southeastern San Diego",
        "Military Facilities",
        "Otay Mesa-Nestor",
        "Otay Mesa",
        "San Ysidro",
        "Tijuana River Valley",
        "Mission Valley",
        "Old Town San Diego",
        "Midway-Pacific Highway",
        "Mid-City:City Heights",
        "Encanto Neighborhoods",
        "Skyline-Paradise Hills",
        "Rancho Bernardo",
        "Mira Mesa",
        "Uptown",
        "Tierrasanta",
        "Torrey Hills",
        "Serra Mesa",
        "Military Facilities",
        "Barrio Logan",
        "San Pasqual",
        "Rancho Penasquitos",
        "Los Penasquitos Canyon",
        "Carmel Valley",
        "Kearny Mesa",
        "Linda Vista",
        "Mid-City:Kensington-Talmadge",
        "Mid-City:Normal Heights",
        "North Park",
        "Balboa Park",
        "Reserve",
        "Downtown",
        "Torrey Pines",
        "Torrey Highlands",
        "Pacific Highlands Ranch",
        "Del Mar Mesa",
        "Rancho Encantada",
        "Scripps Miramar Ranch",
        "University",
        "Reserve",
        "Greater Golden Hill",
        "Black Mountain Ranch",
        "Carmel Mountain Ranch",
        "Via De La Valle",
        "Fairbanks Ranch Country Club",
        "Reserve",
        "Ncfua Subarea Ii",
        "Reserve",
        "Reserve",
        "La Jolla",
        "East Elliott",
        "Miramar Ranch North",
        "Sabre Springs",
        "Navajo",
        "Pacific Beach",
        "Clairemont Mesa",
        "College Area",
        "Mid-City:Eastern Area",
        "Peninsula",
        "Mission Bay Park",
        "Mission Beach",
        "Ocean Beach"
      ],
      private: false
    },
    {
      label: "SLC / Boise",
      value: "boise",
      timezone: "America/Boise",
      zip: 83701,
      lat: 43.618881,
      lng: -116.215019,
      neighborhoods: [
        "Central Rim",
        "West Valley",
        "Collister",
        "North End",
        "South Boise Village",
        "Maple Grove-Franklin",
        "Warm Springs Mesa",
        "Depot Bench",
        "Central Bench",
        "Hillcrest",
        "Quail Ridge",
        "Harrison Blvd",
      ],
      private: false
    },
    {
      label: "Alberta (CA)",
      value: "alberta ca",
      timezone: "America/Edmonton",
      zip: 83701,
      lat: 55.991667,
      lng: -114.376667,
      neighborhoods: [],
      private: false
    },
    {
      label: "Arizona",
      value: "phoenix",
      timezone: "America/Phoenix",
      zip: 85003,
      lat: 34.286667,
      lng: -111.656944,
      neighborhoods: [],
      private: false
    },
    {
      label: "Las Vegas",
      value: "las vegas",
      timezone: "America/Los_Angeles",
      zip: 85003,
      lat: 34.286667,
      lng: -111.656944,
      neighborhoods: [],
      private: false
    },
    {
      label: "Detroit",
      value: "detroit",
      timezone: "America/Detroit",
      zip: 85003,
      lat: 34.286667,
      lng: -111.656944,
      neighborhoods: [],
      private: false
    },
    {
      label: "Philadelphia",
      value: "philadelphia",
      timezone: "America/New_York",
      zip: 85003,
      lat: 34.286667,
      lng: -111.656944,
      neighborhoods: [],
      private: false
    },
    {
      label: "Washington DC",
      value: "washington dc",
      timezone: "America/New_York",
      zip: 85003,
      lat: 34.286667,
      lng: -111.656944,
      neighborhoods: [],
      private: false
    },
  ],

  categories: [
    {
      label: "Genres",
      subcategories: [
        "60s",
        "70s",
        "80s",
        "90s",
        "African",
        "Asian",
        "Caribbean",
        "Classical",
        "EDM",
        "House",
        "Indie / Alt",
        "Synth",
        "Glam / Powerpop",
        "Techno",
        "Traditional",
        "Acid / Electro",
        "Bass / Dubstep",
        "Breaks / Beats",
        "Disco / Boogie",
        "Dub / Reggae",
        "Hip Hop / R&B",
        "Indie Dance",
        "Latin / Español",
        "Metal / Heavy",
        "Middle Eastern",
        "Pop / Top 40s",
        "Psych / Garage",
        "Rock N Roll",
        "Soul / Funk",
        "Ambient / New Age",
        "Deep / Tech House",
        "EBM / Industrial",
        "Folk / Singer-Songwriter",
        "Goth / Deathrock",
        "Indie Dance / Nu-Disco",
        "Jazz / Freeform",
        "Jungle / Drum & Bass",
        "Noise / Experimental",
        "Postpunk / New Wave",
        "Punk / Hardcore",
        "Shoegaze / Dreampop",
        "World Club / New Club",
      ],
    },
    {
      label: "Other Categories",
      subcategories: [
        "Art",
        "Cannabis",
        "Charity",
        "Comedy",
        "Day Party",
        "Fashion",
        "Festival",
        "Film",
        "Free",
        "Late Night",
        "Literary",
        "Outdoors",
        "POC",
        "Community / Activism",
        "DJ / Dance Party",
        "Esoteric / Spiritual",
        "Fitness / Wellness",
        "Food / Dining",
        "Gaming / eSports",
        "Lecture / Panel",
        "LGBTQIA+",
        "Live Music",
        "Online / Virtual",
        "Performing Arts",
        "Science / Tech",
        "Sex / Fetish",
      ],
    },
  ],
};

export const getFilters = () => {
  const labelToKey = (label) => {
    return label
      .replace(/\s?&\s?/g, "_")
      .replace(/\s?\/\s?/g, "_")
      .replace(/\s/g, "_")
      .toLowerCase();
  };

  const labelToValue = (label) => {
    return label.toLowerCase();
  };

  let expandedLocations = filters.locations.map((location) => {
    let expandedLocation = Object.assign({}, location);
    expandedLocation.value = location.value || labelToValue(location.label);
    expandedLocation.key = labelToKey(expandedLocation.value);
    expandedLocation.neighborhoods = location.neighborhoods.map(
      (neighborhood) => {
        return {
          value: labelToValue(neighborhood),
          label: neighborhood,
          key: labelToKey(neighborhood),
        };
      }
    );
    return expandedLocation;
  });

  let expandedCategories = filters.categories.map((category) => {
    let expandedCategory = {
      label: category.label,
    };
    expandedCategory.value = labelToValue(category.label);
    expandedCategory.key = labelToKey(category.label);
    expandedCategory.subcategories = category.subcategories.map((category) => {
      return {
        value: labelToValue(category),
        label: category,
        key: labelToKey(category),
      };
    });
    return expandedCategory;
  });

  return {
    locations: expandedLocations,
    categories: expandedCategories,
  };
};
