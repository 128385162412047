import { UPDATE_FILTER, AUTH_USER_GET_SUCCESS } from "@constants/action-types";
import { getFilters } from "../actions/load-filters";
import { eventFiltersAdapter } from "../adapters/storeAdapters";

const data = getFilters();

const initialState = {
  sort_filters: [
    {
      value: "",
      selected: true,
      label: "All Events",
      options: [],
    },
    {
      selected: false,
      value: "featured",
      label: "Featured",
      options: [],
    },
    {
      selected: false,
      value: "recommended",
      label: "Recommended",
      options: [],
    },
  ],
  dates: [
    {
      value: "today",
      label: "Today",
      selected: false,
    },
    {
      value: "tomorrow",
      label: "Tomorrow",
      selected: false,
    },
    {
      value: "this_week",
      label: "This Week",
      selected: false,
    },
    {
      value: "this_weekend",
      label: "This Weekend",
      selected: false,
    },
    {
      value: "next_week",
      label: "Next Week",
      selected: false,
    },
    {
      value: "this_month",
      label: "This Month",
      selected: false,
    },
  ],
  categories: data.categories,
  initialLocationDefined: false,
  location: data.locations,
  breadcrumb: {
    sort: "All Events",
    location: "",
    dates: "",
    categories: "",
  },
  hasStarted: false,
  status: 200,
};

let ogLocations;

export default function eventFilters(state = initialState, action) {
  switch (action.type) {
    case AUTH_USER_GET_SUCCESS: {
      if (action.payload.status === 200) {
        if (action.payload.data.role === "curator" && ogLocations) {
          return Object.assign({}, state, {
            location: ogLocations,
          });
        }
      }

      return state;
    }
    case UPDATE_FILTER: {
      const updatedState = eventFiltersAdapter(state, action.filter);
      return Object.assign({}, state, updatedState);
    }
    default: {
      return state;
    }
  }
}
