import fetchJsonp from "fetch-jsonp";

function checkStatus(response) {
  if (response.ok) {
    return response;
  }

  const error = new Error("jsonp request failed");
  error.response = response;
  throw error;
}

export default function fetchJSONP(url) {
  return fetchJsonp(url, { timeout: 15000 }).then(checkStatus);
}
