export const createValidator =
  (error_message, checkedFunction) =>
  (...value) => {
    return checkedFunction(...value) ? undefined : error_message;
  };

export const combineValidators =
  (...validators) =>
  (...value) => {
    for (let i = 0; i < validators.length; ++i) {
      const error_message = validators[i](...value);
      if (error_message !== undefined) return error_message;
    }
  };

export const combineNormalizers =
  (...normalizers) =>
  (value) =>
    normalizers.reduce(
      (currentResult, normalizer) => normalizer(currentResult),
      value
    );

export const setCustomValidityDomElement = (el, error) => {
  if (typeof el === "string") {
    el = document.getElementsByName(el)[0];
  }
  if (el instanceof HTMLElement) {
    el.setCustomValidity(error);
    el.reportValidity();
  }
};
