import { UPDATE_ACCOUNT } from "../../constants/action-types";

import { updateAccount as updateAccountRequest } from "../../services/http_services";

export function updateAccount(data) {
  const updateAccountPromise = async () => {
    try {
      const profile = await updateAccountRequest(data);
      return {
        data: profile,
      };
    } catch (e) {
      throw e;
    }
  };
  return {
    type: UPDATE_ACCOUNT,
    payload: {
      promise: updateAccountPromise(),
    },
  };
}
