import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "gatsby";
import FbIcon from "@assets/svg/svgui/fb-icon.svg";
import TwitterIcon from "@assets/svg/svgui/twitter-icon.svg";
import EmailIcon from "@assets/svg/svgui/email-icon.svg";

class SimpleDropdown extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    items: PropTypes.array,
    theme: PropTypes.string,
    eventName: PropTypes.string,
    widthType: PropTypes.string,
  };

  renderItems = () => {
    return this.props.items.map((item, i) => {
      if (item.action) {
        return (
          <a onClick={() => item.action()} key={item.key}>
            <li
              className={`${
                item.selected === true
                  ? "dropdown__item--selected"
                  : "dropdown__item"
              }`}
            >
              {item.thumbnail && item.thumbnail !== "" && (
                <div className="dropdown__item__thumb">
                  <img src={item.thumbnail} alt="" />
                </div>
              )}
              {item.picture && item.picture.url && (
                <div className="dropdown__item__thumb">
                  <img src={item.picture.url} alt="" />
                </div>
              )}
              <div className="dropdown__item__label">{item.label}</div>
            </li>
          </a>
        );
      }

      if (!item.route) {
        return (
          <li
            style={{ padding: 0 }}
            key={item.key}
            className={`${
              item.selected === true
                ? "dropdown__item--selected"
                : "dropdown__item"
            }`}
          >
            {item.thumbnail && item.thumbnail !== "" && (
              <div className="dropdown__item__thumb">
                <img src={item.thumbnail} alt="" />
              </div>
            )}
            {item.picture && item.picture.url && (
              <div className="dropdown__item__thumb">
                <img src={item.picture.url} alt="" />
              </div>
            )}
            <div className="dropdown__item__label">{item.label}</div>
          </li>
        );
      }

      return (
        <Link to={item.route} key={item.key}>
          <li
            className={`${
              item.selected === true
                ? "dropdown__item--selected"
                : "dropdown__item"
            }`}
          >
            {item.thumbnail && item.thumbnail !== "" && (
              <div className="dropdown__item__thumb">
                <img src={item.thumbnail} alt="" />
              </div>
            )}
            {item.picture && item.picture.url && (
              <div className="dropdown__item__thumb">
                <img src={item.picture.url} alt="" />
              </div>
            )}
            <div className="dropdown__item__label">{item.label}</div>
          </li>
        </Link>
      );
    });
  };

  render() {
    return (
      <div
        className={`${
          this.props.theme
            ? `dropdown dropdown__simple ${this.props.theme}`
            : "dropdown dropdown__simple"
        }`}
        style={{
          width: this.props.widthType === "auto" ? "auto" : "100%",
        }}
      >
        <ul>
          {this.props.type === "event-options" && (
            <li className="dropdown__item">
              <div className="event-tile-dropdown__social-header">
                {"SHARE EVENT"}
              </div>
              <div className="event-tile-dropdown__social">
                <a
                  href={
                    "https://www.facebook.com/sharer/sharer.php?u=" +
                    encodeURIComponent(window.location.href)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="SVGInline fb-icon">
                    <FbIcon
                      className="SVGInline-svg fb-icon-svg"
                      style={{ width: 32, height: 32 }}
                    />
                  </div>
                </a>
                <a
                  href={
                    "https://twitter.com/intent/tweet?url=" +
                    encodeURIComponent(window.location.href)
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="SVGInline twitter-icon">
                    <TwitterIcon
                      className="SVGInline-svg twitter-icon-svg"
                      style={{ width: 32, height: 32 }}
                    />
                  </div>
                </a>
                <a
                  href={
                    "mailto:?body=" +
                    encodeURIComponent(window.location.href) +
                    "&subject=" +
                    (this.props.eventName ? this.props.eventName : "")
                  }
                >
                  <div className="SVGInline email-icon">
                    <EmailIcon
                      className="SVGInline-svg email-icon-svg"
                      style={{ width: 32, height: 32 }}
                    />
                  </div>
                </a>
              </div>
            </li>
          )}
          {this.renderItems()}
        </ul>
      </div>
    );
  }
}

export default SimpleDropdown;
