import React, { Component } from "react";
import _isEmpty from "lodash/isEmpty";
import _values from "lodash/values";
import ModalHeader from "../../components/modals/ModalHeader";
import InputField from "../../components/fields/InputField";
import { ttfRegistration } from "../../services/http_services/to_ttf_requests";
import {
  isEmailValidator,
  requiredValidator,
} from "../../services/validation_services";

class RegistrationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      password_confirmation: "",
      error: {},
      serverError: {},
      isSubmitDisabled: false,
    };
  }

  onInputChange = (fieldKey, value) => {
    this.setState(
      {
        [fieldKey]: value,
      },
      () => {
        this.validateInput(fieldKey);
      }
    );
  };

  validateInput = (key) => {
    let error;

    switch (key) {
      case "email":
        error = isEmailValidator()(this.state.email) || "";
        break;
      case "first_name":
      case "last_name":
      case "password":
      case "password_confirmation":
        error = requiredValidator()(this.state[key]) || "";
        break;
      default:
        error = "";
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        error: { ...prevState.error, [key]: error },
      };
    });
  };

  handleRegistration = async (e) => {
    e.preventDefault();
    this.setState({isSubmitDisabled: true})
    const { removeModal } = this.props;
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      error,
    } = this.state;

    const isEmptyErrorObj = _values(error).every(_isEmpty);

    if (!isEmptyErrorObj) {
      return;
    }

    try {
      await ttfRegistration({
        first_name,
        last_name,
        email,
        password,
        password_confirmation,
      });

      removeModal("registration_modal");
    } catch (e) {
      const { data } = e.response;

      this.setState({
        serverError: data.message,
        isSubmitDisabled: false,
      });
    }
  };

  handleLoginModalOpen = () => {
    this.props.removeModal("registration_modal");
    if (this.props.toggleNav) {
      this.props.toggleNav();
    }
    this.props.openLoginModal();
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      error,
      serverError,
      isSubmitDisabled
    } = this.state;

    return (
      <div className="rn_window">
        <ModalHeader title="Create an Account" />
        <form
          className="registration_block_form"
          onSubmit={this.handleRegistration}
        >
          <div className="registration_window_content">
            {serverError.email && (
              <p className="error-text">{serverError.email}</p>
            )}
            {serverError.password ? (
              serverError.password.length > 1 ? (
                <div>
                  <p className="error-text">{serverError.password[0]}</p>
                  <p className="error-text">{serverError.password[1]}</p>
                </div>
              ) : (
                <p className="error-text">{serverError.password}</p>
              )
            ) : null}
            <div className="registration_name_block">
              <div className="registration_window_block_content">
                <InputField
                  editable={Boolean(true)}
                  required={Boolean(true)}
                  label="First Name"
                  name="first_name"
                  type="text"
                  initialValue=""
                  value={first_name}
                  handleChange={this.onInputChange}
                  error={error.first_name}
                />
              </div>
              <div className="registration_window_block_content">
                <InputField
                  editable={Boolean(true)}
                  required={Boolean(true)}
                  label="Last Name"
                  name="last_name"
                  type="text"
                  initialValue=""
                  value={last_name}
                  handleChange={this.onInputChange}
                  error={error.last_name}
                />
              </div>
            </div>
            <div className="registration_email_block">
              <InputField
                editable={Boolean(true)}
                required={Boolean(true)}
                label="Email Address"
                name="email"
                type="email"
                initialValue=""
                value={email}
                handleChange={this.onInputChange}
                error={error.email}
              />
            </div>
            <div className="registration_name_block">
              <div className="registration_window_block_content">
                <InputField
                  minLength={6}
                  maxLength={32}
                  editable={Boolean(true)}
                  required={Boolean(true)}
                  label="Password"
                  name="password"
                  type="password"
                  initialValue=""
                  value={password}
                  handleChange={this.onInputChange}
                  error={error.password}
                />
              </div>
              <div className="registration_window_block_content">
                <InputField
                  editable={Boolean(true)}
                  required={Boolean(true)}
                  label="Confirm Password"
                  name="password_confirmation"
                  type="password"
                  initialValue=""
                  value={password_confirmation}
                  handleChange={this.onInputChange}
                  error={error.password_confirmation}
                />
              </div>
            </div>
            <div className="actions-block">
              <div className="registration_submit_block">
                <div className="registration_submit_sub_block">
                  <button disabled={isSubmitDisabled} type="submit">SUBMIT</button>
                </div>
              </div>
              <div className="registration_submit_block">
                <div
                  className="registration_already_have_account_block"
                  onClick={this.handleLoginModalOpen}
                >
                  <h2>OH WAIT</h2>
                  <p>I HAVE AN ACCOUNT</p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default RegistrationModal;
