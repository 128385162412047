import update from "immutability-helper";
import {
  UI_NAV_SHOW,
  UI_NAV_HIDE,
  UI_NAV_TOGGLE,
  UI_HEADER_BG_SHOW,
  UI_HEADER_BG_HIDE,
  UI_HEADER_LOCK_VISIBILITY,
  UI_TOAST_SHOW,
  UI_LIST_VIEW_TYPE,
  RECOMMEND_EVENT_START,
  RECOMMEND_EVENT_SUCCESS,
  RECOMMEND_EVENT_ERROR,
  UPDATE_ME_START,
  UPDATE_ME_SUCCESS,
  UI_TOGGLE_FILTER_BAR,
  UI_TOGGLE_TICKETING_OVERLAY,
  ME_DATA_UPDATE_START,
  ME_DATA_UPDATE_SUCCESS,
  ME_DATA_UPDATE_ERROR,
  SHOW_UI_LOADER,
  HIDE_UI_LOADER,

  // modals
  CREATE_MODAL,
  REMOVE_MODAL,
  REMOVE_ALL_MODAL,
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_ERROR,
  SIGN_UP_NEWSLETTER_START,
  SIGN_UP_NEWSLETTER_SUCCESS,
  LOAD_PAGINATION_EVENTS_START,
  LOAD_PAGINATION_EVENTS_ERROR,
  LOAD_PAGINATION_EVENTS,
  LOAD_PAGINATION_EVENTS_SUCCESS,
} from "../constants/action-types";

const initialState = {
  navOpen: false,
  showLoader: false,
  headerVisibilityLocked: false,
  headerBGVisible: false,
  headerTheme: "white",
  filterTab: "",
  listViewType: "list",
  ticketingOverlay: false,
  newsletterSignup: {
    status: -1,
    data: "",
  },
  toast: {
    id: new Date(),
    message: "",
    intentStyle: undefined,
  },
  modals: {
    modals: [],
  },
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    // modals
    case CREATE_MODAL: {
      const { modal, close_others } = action.data;
      let modals = close_others ? [] : state.modals.modals;
      if (!modals.find((m) => m.id === modal.id)) {
        modals = [...modals, modal];
      }
      return update(state, { modals: { modals: { $set: modals } } });
    }
    case REMOVE_MODAL: {
      const { id } = action.data;
      let modals = state.modals.modals.filter((m) => m.id !== id);
      return update(state, { modals: { modals: { $set: modals } } });
    }
    case REMOVE_ALL_MODAL: {
      return update(state, { modals: { modals: { $set: [] } } });
    }

    case UI_TOGGLE_TICKETING_OVERLAY: {
      return Object.assign({}, state, {
        ticketingOverlay: action.show,
        showLoader: action.show,
      });
    }
    case UI_NAV_SHOW: {
      return Object.assign({}, state, {
        navOpen: true,
      });
    }
    case UI_NAV_HIDE: {
      return Object.assign({}, state, {
        navOpen: false,
      });
    }
    case UI_NAV_TOGGLE: {
      let { navOpen } = state;
      navOpen = !navOpen;
      return Object.assign({}, state, {
        navOpen: navOpen,
      });
    }
    case UI_HEADER_BG_SHOW: {
      return Object.assign({}, state, {
        headerBGVisible: true,
      });
    }
    case UI_HEADER_BG_HIDE: {
      return Object.assign({}, state, {
        headerBGVisible: false,
      });
    }
    case UI_HEADER_LOCK_VISIBILITY: {
      return Object.assign({}, state, { ...action.config });
    }
    case UI_TOAST_SHOW: {
      return Object.assign({}, state, {
        toast: {
          id: new Date(),
          message: action.message,
          intentStyle: action.intentStyle,
          forever: action.forever ? true : false,
        },
      });
    }
    case UI_TOGGLE_FILTER_BAR: {
      return Object.assign({}, state, {
        filterTab: action.filterTab,
      });
    }

    case UI_LIST_VIEW_TYPE: {
      return Object.assign({}, state, {
        listViewType: action.listViewType,
      });
    }
    case SIGN_UP_NEWSLETTER_START: {
      return Object.assign({}, state, {
        newsletterSignup: {
          status: -1,
          data: "",
        },
      });
    }
    case SIGN_UP_NEWSLETTER_SUCCESS: {
      return Object.assign({}, state, {
        newsletterSignup: {
          status: action.payload.status,
          data: action.payload.data,
        },
      });
    }
    case RECOMMEND_EVENT_START:
    case UPDATE_ME_START:
    case UPDATE_ACCOUNT_START:
    case ME_DATA_UPDATE_START:
    case LOAD_PAGINATION_EVENTS_START:
    case SHOW_UI_LOADER: {
      return Object.assign({}, state, {
        showLoader: true,
      });
    }
    case RECOMMEND_EVENT_SUCCESS:
    case RECOMMEND_EVENT_ERROR:
    case UPDATE_ME_SUCCESS:
    case ME_DATA_UPDATE_SUCCESS:
    case ME_DATA_UPDATE_ERROR:
    case UPDATE_ACCOUNT_SUCCESS:
    case UPDATE_ACCOUNT_ERROR:
    case LOAD_PAGINATION_EVENTS:
    case LOAD_PAGINATION_EVENTS_ERROR:
    case LOAD_PAGINATION_EVENTS_SUCCESS:
    case HIDE_UI_LOADER: {
      return Object.assign({}, state, {
        showLoader: false,
      });
    }
    default: {
      return state;
    }
  }
}
