import { Component } from "react";
import { connect } from "react-redux";
import { showToast } from "../../actions/ui";
import { authUser } from "../../actions/auth/auth-user";
import { getCookieByName } from "../../utils/cookies";
const cookieName = "X-TF-ECOMMERCE";

class AuthValidator extends Component {
  state = {
    exist: !!getCookieByName("X-TF-ECOMMERCE"),
    interval: null,
  };

  componentDidMount = () => {
    const { getUser } = this.props;

    getUser();
    this.handleCookieChange();
  };

  componentWillUnmount() {
    const { interval } = this.state;
    interval && clearInterval(interval);
  }

  handleCookieChange = () => {
    const interval = setInterval(
      () => this.setState({ exist: !!getCookieByName(cookieName) }),
      500
    );
    this.setState({ interval });
  };

  render() {
    return false;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (message) => {
      dispatch(showToast(message));
    },
    getUser: () => {
      dispatch(authUser()).catch(() => {});
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthValidator);
