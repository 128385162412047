import React, { Component } from "react";
import { Link } from "gatsby";
import { DEFAULT_AVATAR } from "@constants/config";
import SimpleDropdown from "../../components/global/dropdowns/simple-dropdown";
import { DashboardMenuButton } from "../../containers/dashboard";
import { toggleNav } from "../../actions/ui";
import { connect } from "react-redux";

class AccountWidget extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidUpdate = () => {
    const {
      auth: {
        me: { checked, exist },
      },
    } = this.props;
    if (checked && !exist && this.state.dropdownOpen) {
      this.setState(
        Object.assign({}, this.state, {
          dropdownOpen: false,
        })
      );
    }
  };

  onMouseEnter = () => {
    const {
      auth: {
        me: { exist },
      },
    } = this.props;
    if (!exist || this.props.isMobile) return;
    this.setState(
      Object.assign({}, this.state, {
        dropdownOpen: true,
      })
    );
  };

  onMouseLeave = () => {
    const {
      auth: {
        me: { exist },
      },
    } = this.props;
    if (!exist || this.props.isMobile) return;
    this.setState(
      Object.assign({}, this.state, {
        dropdownOpen: false,
      })
    );
  };

  getOptions = () => {
    const { isMobile } = this.props;

    return [
      {
        key: "dashboard",
        label: <DashboardMenuButton isMobile={isMobile} />,
      },
      {
        key: "log_out",
        label: "SIGN OUT",
        route: "/logout",
      },
    ];
  };

  renderMobileItems = () => {
    const options = this.getOptions();
    const { toggleNav } = this.props;
    return options.map((item, i) => {
      if (item.route) {
        return (
          <Link onClick={toggleNav} key={item.id || item.key} to={item.route}>
            <div className="account-widget__item clearfix">
              <span>{item.label}</span>
            </div>
          </Link>
        );
      }
      return (
        <div
          key={item.id || item.key}
          className="account-widget__item clearfix"
        >
          <span>{item.label}</span>
        </div>
      );
    });
  };

  render() {
    const {
      auth: {
        me: { exist, data = {} },
      },
    } = this.props;
    let { image } = data;

    image = image || DEFAULT_AVATAR;

    return (
      <div
        className="account-widget-container"
        onMouseEnter={() => this.onMouseEnter()}
        onMouseLeave={() => this.onMouseLeave()}
      >
        {exist && !this.props.isMobile ? (
          <div className="account-widget__username clearfix">
            <span className="account-widget__thumb">
              <img src={image} alt="" />
            </span>
          </div>
        ) : null}
        {this.props.isMobile && (
          <span style={{ display: "flex", flexDirection: "column" }}>
            {this.renderMobileItems()}
          </span>
        )}
        {!this.props.isMobile && (
          <div className="account-widget-dropdown">
            {this.state.dropdownOpen && (
              <SimpleDropdown
                type="account-widget"
                theme="black"
                items={this.getOptions()}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNav: () => {
      dispatch(toggleNav());
    },
  };
};

export default connect(null, mapDispatchToProps)(AccountWidget);
