import React, { Component } from "react";
import Select from "react-select";
import _find from "lodash/find";
import SelectDropdownIcon from "@assets/svg/svgui/select-dropdown-icon.svg";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#e3e3e3" : "#000",
  }),
};

const scrollbarStyles = {
  menuList: (base) => ({
    ...base,

    "::-webkit-scrollbar": {
      width: "5px",
    },
    "::-webkit-scrollbar-track": {
      background: "transsparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: 4,
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
      borderRadius: 4,
    },
  }),
};

const DropdownIndicator = () => {
  return (
    <div className="SVGInline filter-bar__tab-item-icon">
      <SelectDropdownIcon
        className="SVGInline-svg filter-bar__tab-item-icon-svg"
        style={{ width: 15, height: 15, fill: "#a6a6a6" }}
      />
    </div>
  );
};

class SelectField extends Component {
  handleChange = (e) => {
    const { name, handleChange } = this.props;
    handleChange(name, e.value);
  };

  render() {
    let {
      label,
      value,
      defaultValue = {},
      touched = false,
      options,
      required = false,
      editable,
      placeholder = "",
      name,
      selectContainerClass,
      isSearchable,
      useDefaultValue,
      isLoading,
      enableMenuScrollbar = false,
    } = this.props;
    const valueObj = _find(options, (o) => o.value === value);
    const hasValue = !!value || (defaultValue.value && !touched);

    return (
      <div className={`select-container ${selectContainerClass || ""}`}>
        <label
          htmlFor={name}
          className={`select-label ${hasValue ? "has-value" : ""}`}
          onClick={this.handleClick}
        >
          {label}
        </label>
        <Select
          className="select-field required-select-field"
          classNamePrefix="select-field"
          styles={
            enableMenuScrollbar
              ? { ...customStyles, ...scrollbarStyles }
              : customStyles
          }
          options={options}
          isDisabled={!editable || isLoading}
          required={required}
          value={useDefaultValue ? valueObj || defaultValue : valueObj}
          defaultValue={defaultValue}
          placeholder={placeholder}
          name={name}
          onChange={this.handleChange}
          components={{ DropdownIndicator }}
          isSearchable={isSearchable}
          isLoading={isLoading}
        />
        {!editable && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0 }}
            defaultValue={value}
            required={required}
          />
        )}
      </div>
    );
  }
}

export default SelectField;
