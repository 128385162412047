import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "@reducers";
import { responsiveStoreEnhancer } from "redux-responsive";
import { createPromise } from "redux-promise-middleware";

const USE_DEV_TOOLS =
  process.env.DEV && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

export default function configureStore(options = {}) {
  const { initialState = {}, browserHistory = {} } = options;

  const middlewares = [];
  middlewares.push(
    createPromise({
      promiseTypeSuffixes: ["START", "SUCCESS", "ERROR"],
    }),
    thunk
  );

  const composeEnhancers = USE_DEV_TOOLS
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("@reducers", () => {
      const nextRootReducer = require("@reducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
