import React from 'react'
import RNLogoClean from '@assets/svg/svgui/rn-logo-clean.svg'

export default class ModalHeader extends React.Component {
  render() {
    const { title } = this.props
    return (
      <div className="modal-header">
        <div className="header-svg-container">
          <div className="header-logo">
            <div className="header-svg-component">
              <div className="SVGInline rn-wordmark-svg nav-wordmark">
                <RNLogoClean
                  className="SVGInline-svg rn-wordmark-svg nav-wordmark-png"
                  style={{ width: 50, height: 50, fill: "#fff" }}
                />
              </div>
            </div>
            <div className="header-text-container">{'Restless Nites'}</div>
          </div>

          <div className="header-subtext-container">
            {'POWERED BY'}<br/>
            {'THE'}<strong>{'TICKET'}</strong>{'FAIRY'}
          </div>
        </div>
        <div className="modal-title">{title}</div>
      </div>
    )
  }
}
