import {
  LOAD_CURATORS_START,
  LOAD_CURATORS_SUCCESS,
  LOAD_CURATORS_ERROR,
} from "../constants/action-types";

const initialState = {
  hasFailed: false,
  hasStarted: false,
  data: {
    entities: {},
    result: [],
  },
  score: {
    entities: {},
    result: [],
  },
  dataByUsername: {
    entities: {},
    result: [],
  },
  dataByEmail: {
    entities: {},
    result: [],
  },
  searchData: [],
  searchStarted: false,
  userEventsLoading: false,
  userEvents: new Map(),
  status: -1,

  curators: {
    data: [],
    exist: false,
    loading: false,
  },
};

export default function eventUsers(state = initialState, action) {
  switch (action.type) {
    case LOAD_CURATORS_START: {
      return {
        ...state,
        curators: {
          data: [],
          exist: false,
          loading: true,
        },
      };
    }

    case LOAD_CURATORS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        curators: {
          data,
          exist: true,
          loading: false,
        },
      };
    }

    case LOAD_CURATORS_ERROR: {
      return {
        ...state,
        curators: {
          exist: false,
          loading: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}
