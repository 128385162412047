import { connect } from "react-redux";
import { toggleNav } from "../../actions/ui";
import MenuBar from "../../components/global/menu-bar";
import { updateFilter } from "../../actions/update-filter";
import { getActiveLocations, currentLocation } from "../../selectors/filters";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    regions: getActiveLocations(state),
    currentRegion: currentLocation(state),
    navOpen: state.ui.navOpen,
    headerBGVisible: state.ui.headerBGVisible,
    headerTheme: state.ui.headerTheme,
    headerVisibilityLocked: state.ui.headerVisibilityLocked,
    platform: state.platform,
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNav: () => {
      dispatch(toggleNav());
    },
    updateFilter: (data) => {
      dispatch(updateFilter(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
