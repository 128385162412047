import {
  UI_NAV_HIDE,
  UI_NAV_SHOW,
  UI_NAV_TOGGLE,
  UI_HEADER_BG_SHOW,
  UI_HEADER_BG_HIDE,
  UI_HEADER_LOCK_VISIBILITY,
  UI_TOAST_SHOW,
  UI_TOGGLE_FILTER_BAR,
  UI_LIST_VIEW_TYPE,
  UI_TOGGLE_TICKETING_OVERLAY,
  SHOW_UI_LOADER,
  HIDE_UI_LOADER,
  REMOVE_MODAL,
  CREATE_MODAL,
  REMOVE_ALL_MODAL,
} from "../constants/action-types";

import { Intent } from "@blueprintjs/core";
import { changeBodyOverflow } from "../utils/body-overflow";

export function hideNav() {
  return {
    type: UI_NAV_HIDE,
  };
}

export function showNav() {
  return {
    type: UI_NAV_SHOW,
  };
}

export function hideHeaderBG() {
  return {
    type: UI_HEADER_BG_HIDE,
  };
}

export function configureHeader(config) {
  return {
    type: UI_HEADER_LOCK_VISIBILITY,
    config,
  };
}

export function showHeaderBG() {
  return {
    type: UI_HEADER_BG_SHOW,
  };
}

export function toggleFilterBar(filterTab) {
  return {
    type: UI_TOGGLE_FILTER_BAR,
    filterTab,
  };
}

export function showToast(message, intentStyle, forever = false) {
  return {
    type: UI_TOAST_SHOW,
    message,
    intentStyle: intentStyle ? intentStyle : Intent.DANGER,
    forever: forever,
  };
}

export function toggleNav() {
  return {
    type: UI_NAV_TOGGLE,
  };
}

export function toggleTicketingOverlay(show) {
  return {
    type: UI_TOGGLE_TICKETING_OVERLAY,
    show,
  };
}

export function setListViewType(listViewType) {
  return {
    type: UI_LIST_VIEW_TYPE,
    listViewType,
  };
}

export function createModal(modal, close_others = true) {
  changeBodyOverflow("hidden");
  return {
    type: CREATE_MODAL,
    data: { modal, close_others },
  };
}

export function removeModal(id) {
  changeBodyOverflow("auto");
  return {
    type: REMOVE_MODAL,
    data: { id },
  };
}

export function removeAllModal() {
  changeBodyOverflow("auto");
  return {
    type: REMOVE_ALL_MODAL,
    data: {},
  };
}

export function showGlobalLoader() {
  return {
    type: SHOW_UI_LOADER,
  };
}

export function hideGlobalLoader() {
  return {
    type: HIDE_UI_LOADER,
  };
}
