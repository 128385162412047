import { LOAD_PAGINATION_EVENTS, LOAD_PAGINATION_EVENTS_CAROUSEL, LOAD_PAGINATION_EVENTS_CAROUSEL_START, LOAD_PAGINATION_EVENTS_ERROR, LOAD_PAGINATION_EVENTS_START, LOAD_PAGINATION_EVENTS_SUCCESS } from '../constants/action-types';

export function loadPaginationEvents(payload, page) {
	return {
		type: LOAD_PAGINATION_EVENTS,
		payload: {
			payload,
			page,
		}
	}
}

export function loadPaginationEventsSuccess() {
	return {
		type: LOAD_PAGINATION_EVENTS_SUCCESS,
	}
}

export function loadPaginationEventsError() {
	return {
		type: LOAD_PAGINATION_EVENTS_ERROR,
	}
}

export function loadPaginationEventsStart() {
	return {
		type: LOAD_PAGINATION_EVENTS_START,
	}
}

export function loadPaginationEventsCarousel(payload) {
	return {
		type: LOAD_PAGINATION_EVENTS_CAROUSEL,
		payload,
	}
}

export function loadPaginationEventsCarouselStart() {
	return {
		type: LOAD_PAGINATION_EVENTS_CAROUSEL_START,
	}
}