export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_START = "LOAD_USER_START";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";

export const LOAD_CURATORS = "LOAD_CURATORS";
export const LOAD_CURATORS_START = "LOAD_CURATORS_START";
export const LOAD_CURATORS_SUCCESS = "LOAD_CURATORS_SUCCESS";
export const LOAD_CURATORS_ERROR = "LOAD_CURATORS_ERROR";

export const UPDATE_FILTER = "UPDATE_FILTER";

export const SHOW_UI_LOADER = "SHOW_UI_LOADER";
export const HIDE_UI_LOADER = "HIDE_UI_LOADER";

export const LOAD_BLAST_EVENT = "LOAD_BLAST_EVENT";
export const LOAD_BLAST_EVENT_START = "LOAD_BLAST_EVENT_START";
export const LOAD_BLAST_EVENT_SUCCESS = "LOAD_BLAST_EVENT_SUCCESS";
export const LOAD_BLAST_EVENT_ERROR = "LOAD_BLAST_EVENT_ERROR";

export const PRELOAD_BLAST_EVENT = "PRELOAD_BLAST_EVENT";
export const PRELOAD_BLAST_EVENT_START = "PRELOAD_BLAST_EVENT_START";
export const PRELOAD_BLAST_EVENT_SUCCESS = "PRELOAD_BLAST_EVENT_SUCCESS";
export const PRELOAD_BLAST_EVENT_ERROR = "PRELOAD_BLAST_EVENT_ERROR";

// AUTH
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_START = "AUTH_LOGOUT_START";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_ERROR = "AUTH_LOGOUT_ERROR";

export const AUTH_USER_GET = "AUTH_USER_GET";
export const AUTH_USER_GET_START = "AUTH_USER_GET_START";
export const AUTH_USER_GET_SUCCESS = "AUTH_USER_GET_SUCCESS";
export const AUTH_USER_GET_ERROR = "AUTH_USER_GET_ERROR";

export const EVENTS_PAGINATE = "EVENTS_PAGINATE";
export const EVENTS_PAGINATE_RESET = "EVENTS_PAGINATE_RESET";
export const EVENTS_SCROLL_OFFSET = "EVENTS_SCROLL_OFFSET";

export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const SEARCH_PAGINATE = "SEARCH_PAGINATE";

export const UPDATE_ME = "UPDATE_ME";
export const UPDATE_ME_START = "UPDATE_ME_START";
export const UPDATE_ME_SUCCESS = "UPDATE_ME_SUCCESS";
export const UPDATE_ME_ERROR = "UPDATE_ME_ERROR";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_START = "UPDATE_ACCOUNT_START";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR";

export const ME_DATA_UPDATE = "ME_DATA_UPDATE";
export const ME_DATA_UPDATE_START = "ME_DATA_UPDATE_START";
export const ME_DATA_UPDATE_SUCCESS = "ME_DATA_UPDATE_SUCCESS";
export const ME_DATA_UPDATE_ERROR = "ME_DATA_UPDATE_ERROR";

// TAKES CARE OF BOTH: RECOMMEND AND UNRECOMMEND
export const RECOMMEND_EVENT = "RECOMMEND_EVENT";
export const RECOMMEND_EVENT_START = "RECOMMEND_EVENT_START";
export const RECOMMEND_EVENT_SUCCESS = "RECOMMEND_EVENT_SUCCESS";
export const RECOMMEND_EVENT_ERROR = "RECOMMEND_EVENT_ERROR";

// UI
export const UI_LIST_VIEW_TYPE = "UI_LIST_VIEW_TYPE";
export const UI_NAV_HIDE = "UI_NAV_HIDE";
export const UI_NAV_SHOW = "UI_NAV_SHOW";
export const UI_NAV_TOGGLE = "UI_NAV_TOGGLE";
export const UI_HEADER_LOCK_VISIBILITY = "UI_HEADER_LOCK_VISIBILITY";
export const UI_HEADER_BG_SHOW = "UI_HEADER_BG_SHOW";
export const UI_HEADER_BG_HIDE = "UI_HEADER_BG_HIDE";
export const UI_TOAST_SHOW = "UI_TOAST_SHOW";
export const UI_TOGGLE_FILTER_BAR = "UI_TOGGLE_FILTER_BAR";
export const UI_TOGGLE_TICKETING_OVERLAY = "UI_TOGGLE_TICKETING_OVERLAY";

// MODALS
export const CREATE_MODAL = "CREATE_MODAL";
export const UPDATE_MODAL = "UPDATE_MODAL";
export const REMOVE_MODAL = "REMOVE_MODAL";
export const REMOVE_ALL_MODAL = "REMOVE_ALL_MODAL";

// get countries list
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

//Checkout
export const SET_CHECKOUT_DATA = "SET_CHECKOUT_DATA";
export const CLEAR_CHECKOUT_DATA = "CLEAR_CHECKOUT_DATA";

//Tickets
export const SET_TICKETS_SUCCESS = "SET_TICKETS_SUCCESS";

export const SIGN_UP_NEWSLETTER = "SIGN_UP_NEWSLETTER";
export const SIGN_UP_NEWSLETTER_START = "SIGN_UP_NEWSLETTER_START";
export const SIGN_UP_NEWSLETTER_SUCCESS = "SIGN_UP_NEWSLETTER_SUCCESS";
export const SIGN_UP_NEWSLETTER_ERROR = "SIGN_UP_NEWSLETTER_ERROR";

export const SET_ACCESS_FILTER = "SET_ACCESS_FILTER";

export const LOAD_PAGINATION_EVENTS = 'LOAD_PAGINATION_EVENTS';
export const LOAD_PAGINATION_EVENTS_START = 'LOAD_PAGINATION_EVENTS_START';
export const LOAD_PAGINATION_EVENTS_SUCCESS = 'LOAD_PAGINATION_EVENTS_SUCCESS';
export const LOAD_PAGINATION_EVENTS_ERROR = 'LOAD_PAGINATION_EVENTS_ERROR';

export const LOAD_PAGINATION_EVENTS_CAROUSEL = "LOAD_PAGINATION_EVENTS_CAROUSEL"
export const LOAD_PAGINATION_EVENTS_CAROUSEL_START = "LOAD_PAGINATION_EVENTS_CAROUSEL_START"