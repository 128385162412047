import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { DashboardMenu } from ".";
import { createModal, removeModal, toggleNav } from "../../actions/ui";

class DashboardMenuButton extends PureComponent {
  openModal = () => {
    const { createModal, close_others, navOpen, toggleNav, browser } =
      this.props;
    const isMobile = browser.lessThan.medium;

    const modal = {
      id: "dashboard_menu",
      Component: () => (
        <div
          style={{
            maxWidth: isMobile ? "100%" : "350px",
            height: "inherit",
            display: "inline-flex",
          }}
        >
          <DashboardMenu />
        </div>
      ),
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };
    createModal(modal, close_others);

    if (navOpen) {
      toggleNav();
    }
  };

  render() {
    const { ButtonComponent, isMobile } = this.props;
    if (ButtonComponent) {
      return <ButtonComponent onClick={this.openModal} />;
    }
    return (
      <span
        onClick={this.openModal}
        style={{
          height: "100%",
          width: "100%",
          margin: 0,
          padding: isMobile ? "" : "22px",
        }}
      >
        DASHBOARD
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    navOpen: state.ui.navOpen,
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    toggleNav: () => {
      dispatch(toggleNav());
    },
  };
};

const _DashboardMenuButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardMenuButton);

export { _DashboardMenuButton as DashboardMenuButton };
