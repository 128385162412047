// http
import { getCountries } from "../services/http_services";

// action types
import { GET_COUNTRIES } from "../constants/action-types";

const fetchCountries = () => {
  const fetchCountries = async () => {
    try {
      const countries = await getCountries();
      return {
        data: countries,
      };
    } catch (error) {
      throw error;
    }
  };

  return {
    type: GET_COUNTRIES,
    payload: {
      promise: fetchCountries(),
    },
  };
};

export { fetchCountries };
