import { Component } from "react";
import { connect } from "react-redux";
import { updateFilter } from "@actions/update-filter";
import fetchJSONP from "@utils/fetch-jsonp";
import { nearestCity } from "@utils/nearest-city";
import { getActiveLocations } from "@selectors/filters";
import { isWindowDefined } from "../../assets/third-party/blast";

class LocationDetector extends Component {
  constructor(props) {
    super(props);

    this.initialized = false;
    this.state = {
      timedOut: false,
      lat: null,
      lng: null,
    };
  }

  componentDidMount = () => {
    const {
      auth: {
        me: { exist, data },
      },
    } = this.props;
    if (exist && data.region) {
      this.props.setLocation({
        type: "region",
        value: data.region,
        selected: true,
      });
    } else {
      const geoIPTimeout = setTimeout(() => {
        this.setState(
          Object.assign({}, this.state, {
            timedOut: true,
            lat: 34.052235,
            lng: -118.243683,
          })
        );
      }, 3000);

      fetchJSONP("https://get.geojs.io/v1/ip/geo.js")
        .then((response) => {
          return response.json();
        })
        .then((d) => {
          if (!this.state.timedOut) {
            clearTimeout(geoIPTimeout);
            this.setState(
              Object.assign({}, this.state, {
                lat: d.latitude,
                lng: d.longitude,
              })
            );
          } else {
          }
        })
        .catch((ex) => {
          if (!this.state.timedOut) {
            clearTimeout(geoIPTimeout);
            console.error("parsing failed", ex);
            this.setState(
              Object.assign({}, this.state, {
                lat: 34.052235,
                lng: -118.243683,
              })
            );
          }
        });
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      nextState.lat !== null &&
      nextState.lng !== null &&
      nextProps.regions.length > 0 &&
      !this.initialized
    ) {
      this.initialized = true;
      return true;
    }

    return false;
  };

  componentDidUpdate = () => {
    if (this.props.regions.length > 0 && isWindowDefined) {
      const availableCities = this.props.regions.map((region, i) => {
        return [region.value, region.lat, region.lng];
      });

      const city = nearestCity(this.state.lat, this.state.lng, availableCities);

      this.props.setLocation({
        type: "region",
        value: sessionStorage.getItem("chosen_region") || city[0],
        selected: true,
      });
    }
  };

  render() {
    return false;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    regions: getActiveLocations(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (location) => {
      dispatch(updateFilter(location));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetector);
