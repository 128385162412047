import React from "react";
import { Provider } from "react-redux";
//import { hideNav } from '@actions/ui'
//import { createBrowserHistory as createHistory } from 'history'
import configureStore from "@store/configure-store";
//import { calculateResponsiveState } from 'redux-responsive'
//export const history = createHistory()
import App from "./src/containers/app"

export const store = configureStore();
//window && window.addEventListener('resize', () => store.dispatch(calculateResponsiveState(window)))

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <Provider store={store}>
      <App>{element}</App>
    </Provider>
  );
};
