import isomorphicFetch from "isomorphic-fetch";

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else if (response.status === 400) {
    return response;
  } else if (response.status === 401) {
    return response;
  } else if (response.status === 404) {
    return response;
  } else if (response.status === 500) {
    return response;
  }

  return handleError(response);
}

async function handleError(resp) {
  let body, errorMesg;
  try {
    body = await resp.json();
    errorMesg = body.message || body.error || resp.statusText;
  } catch (e) {
    errorMesg = resp.statusText;
  }

  const error = new Error(errorMesg);
  error.code = error.status = resp.status;
  error.response = resp;
  throw error;
}

export default function fetch(url, opts = {}) {
  return isomorphicFetch(url, opts).then(checkStatus);
}
