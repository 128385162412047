import React, { Component } from "react";
import { connect } from "react-redux";
import _find from "lodash/find";
import _get from "lodash/get";
import { fetchCountries } from "../../actions/shared";
import { requiredValidator } from "../../services/validation_services";
import InputField from "../../components/fields/InputField";
import SelectField from "../../components/fields/SelectField";
import { ProfileEditBlock } from "../profile";
import { createModal, removeModal, showToast } from "../../actions/ui";
import { getCountryStates } from "../../services/http_services";

class AccountEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      touched: {},
      error: {},
      statesOptions: [],
    };
  }

  componentDidMount() {
    this.fetchCountries();

    const countryId = _get(this.props.account, "countryId");

    if (countryId) {
      this.setState(
        {
          formData: { countryId },
        },
        () => {
          this.getStates(countryId);
        }
      );
    }
  }

  fetchCountries = () => {
    const { getCountries } = this.props;
    getCountries();
  };

  handleProfileButtonClick = () => {
    const { createModal, close_others } = this.props;
    const modal = {
      id: "account_edit",
      Component: ProfileEditBlock,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };
    createModal(modal, close_others);
  };

  handleChange = (fieldKey, value) => {
    this.setState(
      (prevState) => {
        const formData = {
          ...prevState.formData,
          [fieldKey]: value,
        };

        if (fieldKey === "countryId") {
          const prevSelectedCountry = prevState.formData.countryId;
          if (prevSelectedCountry !== value) {
            delete formData.stateId;
          }
        }

        const touched = { ...prevState.touched, [fieldKey]: true };
        return { formData, touched };
      },
      () => {
        this.onValidate(fieldKey);
      }
    );
  };

  onValidate = (key) => {
    let error = "";
    let errObj = {};
    const {
      formData: { currentPassword = "", newPassword = "" },
    } = this.state;

    switch (key) {
      case "newPassword":
        if (currentPassword.trim()) {
          error = requiredValidator()(newPassword.trim()) || "";
          errObj = { [key]: error };
        } else if (!newPassword) {
          errObj = { ...errObj, currentPassword: "" };
        }
        break;
      case "currentPassword":
        if (newPassword.trim()) {
          error = requiredValidator()(currentPassword.trim()) || "";
          errObj = { [key]: error };
        } else if (!currentPassword) {
          errObj = { ...errObj, newPassword: "" };
        }
        break;
      default:
        error = "";
        errObj = { [key]: error };
    }

    this.setState((prevState) => {
      return {
        ...prevState,
        error: { ...prevState.error, ...errObj },
      };
    });
  };

  onSubmit = async (e) => {
    try {
      e.preventDefault();
      const { onSubmit } = this.props;
      const { formData, error } = this.state;
      const requestData = { ...formData };

      if (error.newPassword || error.currentPassword) {
        return;
      }

      if ("newPassword" in formData && !requestData.newPassword) {
        delete requestData.newPassword;
      }

      if ("currentPassword" in formData && !requestData.currentPassword) {
        delete requestData.currentPassword;
      }

      await onSubmit(requestData);
      this.setState((state) => {
        const formData = {
          ...state.formData,
          newPassword: "",
          currentPassword: "",
        };
        return { formData };
      });
    } catch (e) {}
  };

  getStates = async (id) => {
    try {
      const states = await getCountryStates(id);
      const statesOptions = Object.entries(states).map(([value, label]) => ({
        value,
        label,
      }));
      this.setState({ statesOptions });
    } catch (e) {
      this.props.showToastError("Something went wrong");
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const countryIdFromCurrState = _get(this.state, "formData.countryId");
    const countryIdFromPrevState = _get(prevState, "formData.countryId");

    if (countryIdFromPrevState !== countryIdFromCurrState) {
      this.getStates(countryIdFromCurrState);
    }
  }

  render() {
    const { account, countriesOptions = [], browser } = this.props;
    const { formData, touched, error, statesOptions } = this.state;
    const { newPassword = "", currentPassword = "" } = this.state.formData;
    const {
      firstName,
      lastName,
      email,
      phone = "",
      zipCode = "",
      countryId = "",
      stateId,
      city = "",
    } = account;
    const defaultCountryOption = _find(
      countriesOptions,
      (item) => item.value === countryId
    );
    const defaultStateOption = _find(
      statesOptions,
      (item) => item.value === stateId
    );

    const isMobile = !browser.greaterThan.mobile;
    const isTablet = !isMobile && !browser.greaterThan.large;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form_account-edit">
          <InputField
            maxLength={30}
            required={true}
            label="First Name"
            name="firstName"
            initialValue={firstName}
            value={formData.firstName}
            touched={touched.firstName}
            handleChange={this.handleChange}
            inputContainerClass={"half"}
          />
          <InputField
            maxLength={30}
            required={true}
            label="Last Name"
            name="lastName"
            initialValue={lastName}
            value={formData.lastName}
            touched={touched.lastName}
            handleChange={this.handleChange}
            inputContainerClass={"half"}
          />
          <div className="clear_float" />
          <InputField
            disabled={true}
            maxLength={30}
            label="Email"
            name="email"
            initialValue={email}
            value={formData.email}
            touched={touched.email}
            handleChange={this.handleChange}
          />
          <InputField
            maxLength={30}
            label="Phone (optional)"
            name="phone"
            initialValue={phone}
            value={formData.phone}
            touched={touched.phone}
            handleChange={this.handleChange}
            inputContainerClass={"half"}
          />
          <InputField
            required={true}
            maxLength={30}
            label="Zip Code"
            name="zipCode"
            initialValue={zipCode}
            value={formData.zipCode}
            touched={touched.zipCode}
            handleChange={this.handleChange}
            inputContainerClass={"half"}
          />
          <div className="clear_float" />
          <SelectField
            required={true}
            label="Country"
            name="countryId"
            editable={true}
            value={formData.countryId || "select-country"}
            options={countriesOptions}
            defaultValue={
              defaultCountryOption
                ? defaultCountryOption
                : {
                    value: "select-country",
                    label: "Select Country",
                  }
            }
            useDefaultValue={true}
            handleChange={this.handleChange}
          />
          <SelectField
            required={true}
            label="State / Province"
            name="stateId"
            selectContainerClass={isMobile ? "" : "half"}
            editable={true}
            value={formData.stateId || "select-state"}
            options={statesOptions}
            defaultValue={
              defaultStateOption
                ? defaultStateOption
                : {
                    value: "select-state",
                    label: "Select State",
                  }
            }
            useDefaultValue={true}
            handleChange={this.handleChange}
          />
          <InputField
            required={true}
            maxLength={30}
            label="City"
            name="city"
            initialValue={city}
            value={formData.city}
            touched={touched.city}
            handleChange={this.handleChange}
            inputContainerClass={isMobile ? "" : "half"}
          />
          <InputField
            type="password"
            minLength={6}
            label="Current Password"
            helperText="Leave password fields empty to keep your existing password."
            name="currentPassword"
            value={currentPassword}
            handleChange={this.handleChange}
            inputContainerClass={isMobile ? "" : "half"}
            required={formData.newPassword}
            error={_get(error, "currentPassword")}
          />
          <InputField
            type="password"
            minLength={6}
            maxLength={32}
            label="New Password"
            name="newPassword"
            value={newPassword}
            handleChange={this.handleChange}
            inputContainerClass={isMobile ? "no-margin" : "half"}
            required={formData.currentPassword}
            error={_get(error, "newPassword")}
          />
        </div>
        <div className="form_button-container">
          <button
            type="submit"
            className={`btn ${isMobile || isTablet ? "" : "half"}`}
          >
            {"SUBMIT"}
          </button>
          <button
            type="button"
            className={`btn configure-profile-button no-back  small-text ${
              isMobile || isTablet ? "auto-size" : "half m-l-20 with-border"
            }`}
            onClick={this.handleProfileButtonClick}
          >
            {"CONFIGURE PROFILE PAGE "}
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    browser: state.browser,
    countriesOptions: state.shared.countries.data.countriesOptions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    showToastError: (message) => {
      return dispatch(showToast(message));
    },
    getCountries: () => {
      dispatch(fetchCountries());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditForm);
