import { setConfigs } from "tf-checkout-react";

export const ENV = {
  BASE_URL: process.env.GATSBY_TTF_PUBLIC_API_BASE,
  CLIENT_ID: process.env.GATSBY_TTF_CLIENT_ID,
  CLIENT_SECRET: process.env.GATSBY_TTF_CLIENT_SECRET,
  STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_TTF_STRIPE_PUBLISHABLE_KEY,
  X_SOURCE_ORIGIN: process.env.GATSBY_TTF_PUBLIC_X_SOURCE_ORIGIN,
  ORIGIN: process.env.GATSBY_TTF_PUBLIC_ORIGIN
};

export const setTfCheckoutReactConfigs = () => {
  setConfigs(ENV);
};
