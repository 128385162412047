import { SIGN_UP_NEWSLETTER } from "@constants/action-types";
import { signUpNewsletter } from "../services/http_services";

export function signUpForNewsletter(body) {
  const signUpForNewsletterPostPromise = async () => {
    try {
      const user = await signUpNewsletter(body);
      return {
        data: user,
      };
    } catch (e) {
      throw e;
    }
  };
  return {
    type: SIGN_UP_NEWSLETTER,
    payload: {
      promise: signUpForNewsletterPostPromise(),
    },
  };
}
