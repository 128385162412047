import React, { Component } from 'react'
import ImageDeleteIcon from '@assets/svg/svgui/delete-icon.svg'
import ProfileSquareIcon from '@assets/svg/svgicon/profile-square-icon.png'

class ImageSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: '',
      imagePreviewUrl: props.imageUrl || props.initialValue
    }
  }

  componentDidUpdate = prevProps => {
    const { imageUrl } = this.props
    const { imagePreviewUrl } = this.state
    if (prevProps.imageUrl !== imageUrl && !imagePreviewUrl) {
      this.setState(
        Object.assign({}, this.state, {
          imagePreviewUrl: imageUrl
        })
      )
    }
  }

  getValue = () => {
    return this.state.value
  }

  handleChange = e => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      this.props.handleChange({
        name: this.props.name,
        value: file
      })

      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      })
    }

    reader.readAsDataURL(file)
  }

  clear = e => {
    e.preventDefault()
    this.props.handleChange({
      name: this.props.name,
      value: null
    })

    this.setState({
      file: '',
      imagePreviewUrl: ''
    })
  }

  render() {
    return (
      <div className="image-container">
        <div className="image-select" title="Choose image">
          <img
            alt=""
            className="image-square"
            src={this.state.imagePreviewUrl || ProfileSquareIcon}
          />
          <input className="image-input" type="file" onChange={this.handleChange} />
        </div>
        {this.state.imagePreviewUrl && (
          <div onClick={this.clear}>
            <div className='SVGInline image-delete-icon'>
              <ImageDeleteIcon
                className="SVGInline-svg image-delete-icon-svg"
                style={{ width: 15, height: 15, fill: "#f44336" }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ImageSelect
