import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import TextField from "@components/form/text-field";
import OptionSelect from "@components/form/option-select";
import { signUpForNewsletter } from "@actions/newsletter-signup";
import { getActiveLocations } from "@selectors/filters";
import { showToast } from "../../../actions/ui";
import { Intent } from "@blueprintjs/core";

class NewsLetterSignupModule extends Component {
  static propTypes = {
    // STORE PROPS
    regions: PropTypes.array,
    newsletterSignup: PropTypes.object,
    // STORE FUNCTIONS
    showToast: PropTypes.func.isRequired,
    signUpForNewsletter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.subjectsToValidation = new Set();
    this.state = {
      invalid: false,
      error: "",
      signedUp: false,
      verifying: false,
    };
  }

  validateInput = () => {
    let error = false;
    for (const item of this.subjectsToValidation) {
      const isItemValid = item.isValid();
      if (!isItemValid && !error) error = true;
    }

    if (!error) {
      this.signUp();
    } else {
      this.props.showToast("Missing Fields or incorrect email!");
      this.signalError();
    }
  };

  signalError = (msg) => {
    this.setState(
      Object.assign({}, this.state, {
        error: msg ? msg : this.state.error,
        invalid: true,
      })
    );

    setTimeout(() => {
      this.setState(
        Object.assign({}, this.state, {
          invalid: false,
        })
      );
    }, 500);
  };

  onInputChange = (e) => {
    const obj = {};
    obj[e.name] = e.value;
    this.setState(Object.assign({}, this.state, obj));
  };

  signUp = async () => {
    const { showToast } = this.props;
    const body = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      region: this.state.region.toUpperCase().replace(" ", "_"),
      email: this.state.email,
      zip: this.state.zip,
    };

    this.setState(
      Object.assign({}, this.state, {
        verifying: true,
        invalid: false,
        error: "",
      })
    );

    try {
      await this.props.signUpForNewsletter(body);
      this.setState(
        Object.assign({}, this.state, {
          verifying: false,
          signedUp: true,
        })
      );
    } catch (error) {
      const { isAxiosError, response } = error;
      if (
        isAxiosError &&
        response &&
        response.status === 404 &&
        response.data.message
      ) {
        showToast(response.data.message, Intent.WARNING, true);
      } else {
        showToast("Something Wrong", Intent.DANGER);
      }
      this.setState(
        Object.assign({}, this.state, {
          verifying: false,
        })
      );
    }
  };

  render() {
    return (
      <div className="row newsletter-signup-module">
        <div className="col s12 col--no-margin-bottom">
          <div className="split-content__top-divder"></div>
        </div>
        <div className="col s12 heading heading--secondary">
          <h2 className="heading__headline--centered">
            <span>Newsletter</span>
          </h2>
        </div>
        <div className="col s12">
          {!this.state.signedUp ? (
            <div className="newsletter-signup-form">
              <TextField
                ref={(ref) => {
                  if (ref) this.subjectsToValidation.add(ref);
                }}
                editable={Boolean(true)}
                required={Boolean(true)}
                label=""
                placeholder="First Name"
                name="first_name"
                initialValue=""
                handleChange={this.onInputChange}
              />
              <TextField
                ref={(ref) => {
                  if (ref) this.subjectsToValidation.add(ref);
                }}
                editable={Boolean(true)}
                required={Boolean(true)}
                label=""
                placeholder="Last Name"
                name="last_name"
                initialValue=""
                handleChange={this.onInputChange}
              />
              <TextField
                ref={(ref) => {
                  if (ref) this.subjectsToValidation.add(ref);
                }}
                editable={Boolean(true)}
                required={Boolean(true)}
                label="Email"
                placeholder="Email Address"
                name="email"
                initialValue=""
                handleChange={this.onInputChange}
              />
              <TextField
                ref={(ref) => {
                  if (ref) this.subjectsToValidation.add(ref);
                }}
                editable={Boolean(true)}
                required={Boolean(true)}
                label=""
                placeholder="ZIP Code"
                name="zip"
                initialValue=""
                handleChange={this.onInputChange}
              />
              <OptionSelect
                ref={(ref) => {
                  if (ref) this.subjectsToValidation.add(ref);
                }}
                editable={Boolean(true)}
                required={Boolean(true)}
                label="Home City"
                name="region"
                initialValue={"select-region"}
                options={this.props.regions}
                defaultOption={{ value: "select-region", label: "Select Region" }}
                handleChange={this.onInputChange}
              />
              {this.state.verifying ? (
                <button className="btn newsletter-signup verifying" disabled>
                  {" "}
                </button>
              ) : (
                <button
                  className={`btn newsletter-signup ${this.state.invalid ? "invalid" : ""
                    }`}
                  onClick={() => this.validateInput()}
                >
                  {"SUBMIT"}
                </button>
              )}
            </div>
          ) : (
            <div className="newsletter-signup-success">
              {"THANK YOU FOR SIGNING UP!"}
              <br />
              {"WE'LL BE IN TOUCH."}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: getActiveLocations(state),
    newsletterSignup: state.ui.newsletterSignup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (message) => {
      dispatch(showToast(message));
    },
    signUpForNewsletter: (body) => {
      return dispatch(signUpForNewsletter(body));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsLetterSignupModule);
