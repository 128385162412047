import React, { Fragment } from "react";
import useZendeskWidget, {
  ZendeskWidget,
} from "@components/global/zendesk-button";
import { ZENDESK_KEY } from "@constants/config";

function ScrollToTop({ children }) {
  useZendeskWidget(ZENDESK_KEY, { webWidget: { color: { theme: "#E28560" } } });

  return (
    <Fragment>
      <ZendeskWidget />
      {children}
    </Fragment>
  );
}

export default ScrollToTop;
