import React, { Component } from "react";

class LegacyScreen extends Component {
  render() {
    return (
      <div className="legacy-container">
        <div className="container">
          <div className="row">
            <div className="col s12">
              <div
                className="heading heading--primary"
                style={{ maxWidth: "420px" }}
              >
                <h1 className="heading__headline">{"Time to move on!"}</h1>
                <span className="heading__divider" />
                <p className="heading__copy">
                  {"We are cutting edge, not only with our event curation,"}
                  <br />
                  {"but also with our technology."}
                  <br />
                  <br />
                  {"We do not support "}
                  {this.props.browser}
                  {
                    " for that reason, and recommend upgrading to one of the modern browsers listed below."
                  }
                </p>
              </div>
              <div className="upgrade-browsers">
                <div className="legacy-chrome-browser">
                  <a
                    href="https://www.google.com/chrome/browser/"
                    target="_blank"
                  >
                    <img src={require("@assets/image/ui/chrome-logo.png")} />
                  </a>
                </div>
                <div className="legacy-firefox-browser">
                  <a
                    href="https://www.mozilla.org/en-US/firefox/"
                    target="_blank"
                  >
                    <img src={require("@assets/image/ui/firefox-logo.png")} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LegacyScreen;
