import update from "immutability-helper";
import { findIndex } from "lodash";
import { SET_ACCESS_FILTER } from "@constants/action-types";

import { AccessSubListings } from "@constants/config";

const initialState = {
  hasFailed: false,
  hasStarted: false,
  status: -1,
  data: [],
  location: "/access",
  filters: update(AccessSubListings, {
    0: { selected: { $set: true } },
  }),
};

export default function eventsAccess(state = initialState, action) {
  switch (action.type) {
    case SET_ACCESS_FILTER: {
      const index = findIndex(AccessSubListings, ["value", action.payload]);
      if (index >= 0) {
        const filters = update(AccessSubListings, {
          [index]: { selected: { $set: true } },
        });
        return update(state, {
          filters: { $set: filters },
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
}
