import update from "immutability-helper";
import { get } from "lodash";
import {
  LOAD_USER_START,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
} from "../constants/action-types";

const initialState = {
  loading: false,
  type: "",
  exist: false,
  checked: false,
  data: null,
  slug: "",
};

function user(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_START: {
      const slug = get(action, "meta.slug", "");
      const updates = {
        loading: { $set: true },
        exist: { $set: false },
        checked: { $set: false },
        data: { $set: null },
        type: { $set: "" },
        slug: { $set: slug },
      };
      return update(state, updates);
    }
    case LOAD_USER_SUCCESS: {
      const { data, type } = action.payload;
      const updates = {
        loading: { $set: false },
        exist: { $set: true },
        checked: { $set: true },
        data: { $set: data },
        type: { $set: type },
      };
      return update(state, updates);
    }
    case LOAD_USER_ERROR: {
      const updates = {
        loading: { $set: false },
        exist: { $set: false },
        checked: { $set: true },
      };
      return update(state, updates);
    }
    default: {
      return state;
    }
  }
}

export default user;
