import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ProfileEditBlock } from ".";
import { createModal, removeModal } from "../../actions/ui";

class ProfileEditButton extends PureComponent {
  openModal = () => {
    const { createModal, close_others } = this.props;
    const modal = {
      id: "profile_edit",
      Component: ProfileEditBlock,
      shouldCloseOnOverlayClick: true,
      isCloseBtn: false,
    };
    createModal(modal, close_others);
  };

  render() {
    const { ButtonComponent } = this.props;
    if (ButtonComponent) {
      return <ButtonComponent onClick={this.openModal} />;
    }
    return <span onClick={this.openModal}>Manage</span>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
  };
};

const _ProfileEditButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileEditButton);

export { _ProfileEditButton as ProfileEditButton };
