import React, { Component } from "react";
import { validateEmail } from "@utils/validations";

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue,
      error: false,
      invalid: false,
      isShowPassword: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.initialValue !== this.props.initialValue ||
      prevProps.value !== this.props.value
    ) {
      this.setState(
        Object.assign({}, this.state, {
          value:
            prevProps.initialValue !== this.props.initialValue
              ? this.props.initialValue
              : this.props.value,
        })
      );
    }
  };

  isValid = () => {
    if (!this.props.required) {
      return true;
    } else if (this.props.required && this.getValue() !== "") {
      if (
        this.props.type &&
        this.props.type === "email" &&
        !validateEmail(this.getValue())
      ) {
        this.setState(
          Object.assign({}, this.state, {
            value: this.state.value,
            error: true,
          })
        );

        return false;
      } else if (
        this.props.type &&
        this.props.type === "username" &&
        this.getValue().length < 4
      ) {
        this.setState(
          Object.assign({}, this.state, {
            value: this.state.value,
            error: true,
          })
        );

        return false;
      } else if (
        this.props.characterLimit &&
        this.getValue().length > this.props.characterLimit
      ) {
        this.setState(
          Object.assign({}, this.state, {
            value: this.state.value,
            error: true,
          })
        );

        return false;
      }

      this.setState(
        Object.assign({}, this.state, {
          value: this.state.value,
          error: false,
        })
      );

      return true;
    }

    this.setState(
      Object.assign({}, this.state, {
        value: this.state.value,
        error: true,
      })
    );

    return false;
  };

  getValue = () => {
    return this.state.value || "";
  };

  onShowPassword = (isShowPassword = !this.state.isShowPassword) => {
    this.setState(() => ({ isShowPassword }));
  };

  reset = () => {
    this.setState(
      Object.assign({}, this.state, {
        value: "",
        error: false,
        invalid: false,
      })
    );
  };

  handleChange = (e) => {
    // allow custom formatting funtion to be passed in before anything else.
    const value =
      this.props.formatter && typeof this.props.formatter === "function"
        ? this.props.formatter(e.currentTarget.value)
        : e.currentTarget.value;
    if (this.props.type === "slug") {
      if (value !== "" && value.match(/^[a-z0-9\-]+$/i) === null) {
        return;
      }

      this.updateValue(value.toLowerCase());
    } else if (this.props.type === "username") {
      const regex = /^[a-zA-Z0-9\_]{1,24}$/;
      if (value !== "" && !regex.test(value)) {
        this.setState(
          Object.assign({}, this.state, {
            invalid: true,
          })
        );

        setTimeout(() => {
          this.setState(
            Object.assign({}, this.state, {
              invalid: false,
            })
          );
        }, 500);
      } else {
        this.updateValue(value.toLowerCase());
      }
    } else {
      this.updateValue(value);
    }
  };

  updateValue = (val, bypassHandler = false) => {
    this.setState(
      Object.assign({}, this.state, {
        value: val,
        error: false,
        invalid: false,
      })
    );

    if (this.props.handleChange && !bypassHandler) {
      this.props.handleChange({
        name: this.props.name,
        value: val,
      });
    }
  };

  render() {
    let {
      labelPostfix = null,
      fixedPlaceholder,
      label,
      characterLimit = Infinity,

      required = false,
      editable,
      placeholder = "",
      name,
      maxLength = null,
      minLength = null,
      type = "text",
    } = this.props;

    const { invalid, error, isShowPassword } = this.state;

    const value = this.getValue();

    if (value.length > characterLimit) {
      label = label + " (EXCEEDED " + characterLimit + " CHARACTERS)";
    } else if (characterLimit !== Infinity) {
      label = label + " (MAX " + characterLimit + " CHARACTERS)";
    }

    return (
      <label className={`form__textfield ${invalid ? "invalid" : ""}`}>
        <span
          className={`form__label ${error ? "error" : ""} ${
            fixedPlaceholder ? "with-padding" : ""
          }`}
        >
          {label}
          {required && <span className="form__required-indicator">*</span>}
          {labelPostfix && (
            <div className="form__label-postfix">
              {typeof labelPostfix === "function"
                ? labelPostfix()
                : labelPostfix}
            </div>
          )}
        </span>
        <div className="form__fixed-placeholder-container">
          {fixedPlaceholder && fixedPlaceholder !== "" && (
            <span className="form__fixed-placeholder">{fixedPlaceholder}</span>
          )}
          <input
            type={isShowPassword ? "text" : type}
            disabled={!editable}
            required={required}
            value={value}
            placeholder={placeholder}
            name={name}
            maxLength={maxLength}
            minLength={minLength}
            onChange={this.handleChange}
          />
        </div>
        {type === "password" && value && (
          <label
            onClick={() => this.onShowPassword()}
            style={{ fontSize: "10px", cursor: "pointer" }}
          >
            {isShowPassword ? "hide" : "show"}
          </label>
        )}
      </label>
    );
  }
}

export default TextField;
