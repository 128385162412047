import PropTypes from "prop-types";
import React, { Component } from "react";

class OptionSelect extends Component {
  static propTypes = {
    type: PropTypes.string,
    editable: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    initialValue: PropTypes.string,
    options: PropTypes.array,
    defaultOption: PropTypes.object,
    handleChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const { initialValue } = this.props;
    this.state = {
      value: initialValue,
      error: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.initialValue !== this.props.initialValue) {
      this.setState(
        Object.assign({}, this.state, {
          value: this.props.initialValue,
        })
      );
    }
  };

  isValid = () => {
    if (!this.props.required) {
      return true;
    } else if (
      this.props.required &&
      this.getValue() !== "" &&
      this.getValue() !== this.props.defaultOption.value
    ) {
      this.setState({
        value: this.state.value,
        error: false,
      });

      return true;
    }

    this.setState({
      value: this.state.value,
      error: true,
    });

    return false;
  };

  getValue = () => {
    return this.state.value;
  };

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
      error: false,
    });

    if (this.props.handleChange) {
      this.props.handleChange({
        name: this.props.name,
        value: e.target.value,
      });
    }
  };

  renderOptions = () => {
    return this.props.options.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });
  };

  render() {
    return (
      <label className="form__select">
        <span className={`form__label ${this.state.error ? "error" : ""}`}>
          {this.props.label}
          {this.props.required && (
            <span className="form__required-indicator">*</span>
          )}
        </span>
        <select
          className="region-select"
          value={this.props.value || this.state.value}
          onChange={this.handleChange}
        >
          {this.props.defaultOption && (
            <option value={this.props.defaultOption.value} disabled>
              {this.props.defaultOption.label}
            </option>
          )}
          {this.renderOptions()}
        </select>
      </label>
    );
  }
}

export default OptionSelect;
