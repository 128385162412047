import React, { Component } from 'react'

class TextAreaField extends Component {
  handleChange = e => {
    const { handleChange } = this.props
    const fieldKey = e.target.name
    const value = e.target.value
    handleChange(fieldKey, value)
  }

  render() {
    const {
      label,
      value = '',
      initialValue = '',
      touched = false,
      required = false,
      disabled = false,
      placeholder = '',
      name,
      minLength = null,
      maxLength = null,
      inputContainerClass,
      helperText,
      prefix
    } = this.props

    const hasPrefix = prefix ? 'has-prefix' : ''
    const hasValue = !!value || (initialValue && !touched)

    return (
      <div className={`input-container ${inputContainerClass || ''} ${hasPrefix}`}>
        <label
          htmlFor={name}
          className={`input-label ${hasValue ? 'has-value' : ''}`}
          onClick={this.handleClick}
        >
          {label}
        </label>
        {prefix && <span className="input-field-prefix">{prefix}</span>}
        <textarea
          className={`input-field ${hasPrefix}`}
          name={name}
          value={initialValue && !touched ? initialValue : value}
          disabled={disabled}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
        {helperText && <label className="input-field-helper-text">{helperText}</label>}
      </div>
    )
  }
}

export default TextAreaField
