import React, { Component } from "react";
import { connect } from "react-redux";
import { Intent } from "@blueprintjs/core";
import ModalHeader from "../../components/modals/ModalHeader";
import InputField from "../../components/fields/InputField";
import { ttfOauthLogin } from "../../services/http_services/to_ttf_requests";
import { removeModal, showToast } from "../../actions/ui";
import { authUser } from "../../actions/auth/auth-user";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
      error: "",
      email: this.props.defaultEmailValue || "",
      password: "",
    };
  }

  onInputChange = (fieldKey, value) => {
    this.setState({
      [fieldKey]: value,
      errors: {
        [fieldKey]: undefined,
      },
    });
  };

  submitLogin = async (e) => {
    e.preventDefault();
    const { removeModal, authUser, onLoginSuccess = () => {} } = this.props;
    const { email, password } = this.state;

    try {
      const body = { email, password };

      await ttfOauthLogin(body);
      await authUser();
      removeModal("login_modal");
      onLoginSuccess();
    } catch (error) {
      this.setState({
        serverError: error.response.data.message,
      });
      showToast("Something Wrong", Intent.DANGER);
    }
  };

  render() {
    const {
      invalid,
      error,
      handleCreateAccount,
      handleForgotPassword,
      defaultEmailValue,
    } = this.props;
    const { email, password, serverError } = this.state;

    return (
      <div className="modal-container log_in_modal_page">
        <ModalHeader title={"Login"} />
        <div className="modal-content pb-25p">
          {defaultEmailValue && (
            <p className="info-text-for-login">
              It appears this email is already attached to an account. Please
              log in here to complete your registration.
            </p>
          )}
          {serverError && (
            <div className="server_auth__error">{serverError}</div>
          )}
          <form onSubmit={this.submitLogin}>
            <div className="col s12 m4 offset-m4 col--no-margin-bottom">
              <InputField
                editable={Boolean(true)}
                required={Boolean(true)}
                label="Email Address"
                name="email"
                type="email"
                initialValue=""
                value={email}
                handleChange={this.onInputChange}
                inputContainerClass="full auth_email_passord_input"
              />
              <InputField
                editable={Boolean(true)}
                required={Boolean(true)}
                label="Password"
                name="password"
                type="password"
                initialValue=""
                value={password}
                handleChange={this.onInputChange}
                inputContainerClass="full auth_email_passord_input"
              />
              {error && <div className="auth__error">{error}</div>}
              <div style={{ padding: 10, display: "flex" }}>
                <button
                  className={`btn login ${invalid ? "invalid" : ""}`}
                  onClick={this.validateInput}
                >
                  LOGIN
                </button>
              </div>
              {
                <div>
                  {!defaultEmailValue && handleCreateAccount && (
                    <div
                      style={{
                        color: "#f28051",
                        fontWeight: 600,
                        fontSize: 13,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleCreateAccount}
                    >
                      <h2>NEW HERE?</h2>
                      <p style={{ marginBottom: "1rem" }}>CREATE AN ACCOUNT</p>
                    </div>
                  )}
                  {handleForgotPassword && (
                    <div
                      className="forgot_your_password"
                      onClick={handleForgotPassword}
                    >
                      <h2>FORGOT YOUR PASSWORD?</h2>
                      <p>LET US HELP</p>
                    </div>
                  )}
                </div>
              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authUser: () => {
      dispatch(authUser());
    },
    removeModal: id => {
      dispatch(removeModal(id))
    }
  };
};

export default connect(null, mapDispatchToProps)(LoginModal);
