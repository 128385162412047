import _ from "lodash";

import {
  RECOMMEND_EVENT_SUCCESS,
  EVENTS_PAGINATE,
  EVENTS_SCROLL_OFFSET,
  UPDATE_FILTER,
} from "@constants/action-types";
import { LOAD_PAGINATION_EVENTS, LOAD_PAGINATION_EVENTS_CAROUSEL, LOAD_PAGINATION_EVENTS_CAROUSEL_START, LOAD_PAGINATION_EVENTS_ERROR, LOAD_PAGINATION_EVENTS_START, LOAD_PAGINATION_EVENTS_SUCCESS } from "../constants/action-types";

const preventScrollPositionRoutes = [
  "/",
  "/tickets",
  "/curators",
  "/login",
  "/apply-rn",
  "/signup",
  "/dashboard",
];

const initialState = {
  hasFailed: false,
  hasStarted: false,
  status: -1,
  related: [],
  optimisticRecos: [],
  data: [],
  currentPage: 0,
  currentScrollOffset: 0,
  location: "/",
  carousel: []
};

export default function events(state = initialState, action) {
  switch (action.type) {
    case EVENTS_PAGINATE: {
      return Object.assign({}, state, {
        currentPage: state.currentPage + 1,
      });
    }
    case LOAD_PAGINATION_EVENTS_START: {
      return Object.assign({}, state, {
        hasStarted: true,
        data: []
      })
    }
    case LOAD_PAGINATION_EVENTS_ERROR: {
      return Object.assign({}, state, {
        hasFailed: true
      })
    }
    case LOAD_PAGINATION_EVENTS_SUCCESS: {
      return Object.assign({}, state, {
        status: 200,
        hasStarted: false,
        hasFailed: false,
      })
    }
    case LOAD_PAGINATION_EVENTS: {
      return Object.assign({}, state, {
        data: [...state.data, ...action.payload.payload].sort((a, b) => a.start_time.localeCompare(b.start_time)),
        status: 200,
        hasStarted: false,
        hasFailed: false,
      })      
    }
    case LOAD_PAGINATION_EVENTS_CAROUSEL: {
      return Object.assign({}, state, {
        carousel: [...state.carousel, ...action.payload].sort((a, b) => a.start_time.localeCompare(b.start_time))
      })      
    }
    case LOAD_PAGINATION_EVENTS_CAROUSEL_START: {
      return Object.assign({}, state, {
        carousel: []
      })      
    }
    case EVENTS_SCROLL_OFFSET: {
      if (preventScrollPositionRoutes.indexOf(state.location) < 0) {
        return Object.assign({}, state, {
          currentScrollOffset: action.offset,
        });
      }

      return state;
    }
    case UPDATE_FILTER: {
      return Object.assign({}, state, {
        currentPage: 0,
      });
    }
    case RECOMMEND_EVENT_SUCCESS: {
      const optimisticRecos = state.optimisticRecos.slice(0);
      if (action.payload.recommended) {
        if (state.optimisticRecos.indexOf(action.payload.data.id) < 0) {
          optimisticRecos.push(action.payload.data.id);
        }

        return Object.assign({}, state, {
          optimisticRecos: _.uniq(optimisticRecos),
        });
      }

      _.remove(optimisticRecos, (n) => {
        return n === action.payload.data.id;
      });

      return Object.assign({}, state, {
        optimisticRecos: _.uniq(optimisticRecos),
      });
    }
    default: {
      return state;
    }
  }
}
