/* eslint-disable no-unreachable */
import _get from "lodash/get";
import _map from "lodash/map";
import _clone from "lodash/clone";

const makeString = (baseString = "", addingString = "") => {
  if (baseString) {
    return baseString + ", " + addingString;
  }

  return addingString;
};

export const eventFiltersAdapter = (state, filter) => {
  // filter object
  const filterType = _get(filter, "type");
  const filterValue = _get(filter, "value");
  const isFilterSelected = _get(filter, "selected");

  // state
  const sortFilters = _get(state, "sort_filters") || [];
  const locations = _get(state, "location") || [];
  const categories = _get(state, "categories") || [];
  const dates = _get(state, "dates") || [];
  const breadcrumb = _get(state, "breadcrumb") || {};
  let initialLocationDefined = _get(state, "initialLocationDefined") || false;

  let locationString = "";
  let categoriesString = "";
  let updatedLocations = _clone(locations);

  switch (filterType) {
    case "sort":
      let sortString = "All Events";
      const sort_filters = _map(sortFilters, (item) => {
        const isSame = item.value === filterValue;
        if (isSame) {
          sortString = item.label;
        }

        return { ...item, selected: isSame };
      });

      return { sort_filters, breadcrumb: { ...breadcrumb, sort: sortString } };

    case "region":
      updatedLocations = _map(locations, (item) => ({
        ...item,
        selected: item.value === filterValue,
      }));

      if (!initialLocationDefined && isFilterSelected) {
        initialLocationDefined = true;
      }

      return {
        location: updatedLocations,
        initialLocationDefined,
        breadcrumb: {
          ...breadcrumb,
          location: locationString
            ? locationString
            : updatedLocations.filter((location) => location.selected)[0].label,
        },
      };

    case "neighborhood":
      updatedLocations = _map(locations, (item) => {
        if (item.selected) {
          const updatedNeighborhoods = _map(
            item.neighborhoods,
            (neighborhood) => {
              if (neighborhood.value === filterValue) {
                if (isFilterSelected) {
                  locationString = makeString(
                    locationString,
                    neighborhood.value
                  );
                }

                return { ...neighborhood, selected: !neighborhood.selected };
              }

              return {
                ...neighborhood,
                selected: !!neighborhood.selected,
              };
            }
          );

          return {
            ...item,
            neighborhoods: updatedNeighborhoods,
          };
        }

        return item;
      });

      return {
        location: updatedLocations,
        breadcrumb: { ...breadcrumb, location: locationString },
      };

    case "category":
      const updatedCategories = _map(categories, (category) => {
        const updatedSubCategories = _map(
          category.subcategories,
          (subcategory) => {
            if (subcategory.value === filterValue) {
              if (isFilterSelected) {
                categoriesString = makeString(
                  categoriesString,
                  subcategory.value
                );
              }

              return { ...subcategory, selected: isFilterSelected };
            }

            if (subcategory.selected) {
              categoriesString = makeString(
                categoriesString,
                subcategory.value
              );
            }

            return { ...subcategory, selected: !!subcategory.selected };
          }
        );

        return { ...category, subcategories: updatedSubCategories };
      });

      return {
        categories: updatedCategories,
        breadcrumb: { ...breadcrumb, categories: categoriesString },
      };

    case "dates":
      let datesString = "";
      const updatedDates = _map(dates, (date) => {
        if (date.value === filterValue) {
          if (isFilterSelected) {
            datesString = makeString(datesString, date.label);
          }

          return { ...date, selected: isFilterSelected };
        }

        if (date.selected) {
          datesString = makeString(datesString, date.label);
        }

        return { ...date, selected: false };
      });

      return {
        dates: updatedDates,
        breadcrumb: { ...breadcrumb, dates: datesString },
      };

    case "singleCategory":
      const updatedSingleCategories = _map(categories, (category) => {
        const updatedSubCategories = _map(
          category.subcategories,
          (subcategory) => {
            if (subcategory.value === filterValue) {
              categoriesString = subcategory.label;
              return { ...subcategory, selected: true };
            }

            return { ...subcategory, selected: false };
          }
        );

        return { ...category, subcategories: updatedSubCategories };
      });
      return {
        categories: updatedSingleCategories,
        breadcrumb: { ...breadcrumb, categories: categoriesString },
      };

    default:
      return state;
  }
};
