import React, { Component } from "react";
import { Intent } from "@blueprintjs/core";
import ModalHeader from "../../components/modals/ModalHeader";
import InputField from "../../components/fields/InputField";
import { ttfForgotPassword } from "../../services/http_services/to_ttf_requests";
import { showToast } from "../../actions/ui";

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
      successMessage: "",
    };
  }

  onInputChange = (fieldKey, value) => {
    this.setState({
      [fieldKey]: value,
    });
  };

  handleForgetPassword = async (e) => {
    e.preventDefault();
    const { email } = this.state;

    try {
      try {
        const data = await ttfForgotPassword({ email });

        if (data.message) {
          this.setState({
            successMessage: data.message,
          });
        }
      } catch (e) {
        const { data } = e.response;

        this.setState({
          error: data.message,
        });
      }
    } catch (error) {
      showToast("Something Wrong", Intent.DANGER);
    }
  };

  handleLoginModalOpen = () => {
    this.props.removeModal("registration_modal");
    if (this.props.toggleNav) {
      this.props.toggleNav();
    }
    this.props.openLoginModal();
  };

  render() {
    const { email, successMessage } = this.state;

    return (
      <div className="rn_window forgot_password_container">
        <ModalHeader title="Password Reset" />
        {successMessage ? (
          <div className="forgot_password_info_block">
            <p>{successMessage}</p>
          </div>
        ) : (
          <form
            className="forgot_password_block_form"
            onSubmit={this.handleForgetPassword}
          >
            <div className="forgot_password_window_content">
              <div className="forgot_password_block">
                <InputField
                  required
                  label="Email Address"
                  name="email"
                  type="email"
                  initialValue=""
                  value={email}
                  handleChange={this.onInputChange}
                />
              </div>
              <div className="forgot_password_submit">
                <button type="submit">SUBMIT</button>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default ForgotPasswordModal;
