import React from "react"
const RichTextArea = React.lazy(() =>
  import("./richtext-area")
)

const RichTextAreaWrapper = (props) => {
    const isSSR = typeof window === "undefined"
    if (isSSR) {
        return null
    }
    return (
        <React.Suspense fallback={<div />}>
          <RichTextArea {...props} />
        </React.Suspense>
    )
}

export default RichTextAreaWrapper