const isDocumentDefined = typeof document !== "undefined";

export function setCustomCookie(name, value, days = 5) {
  if (!isDocumentDefined) {
    return "";
  }
  let expires = "";
  if (days) {
    let date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    name +
    "=" +
    (value || "") +
    expires +
    `; path=/; domain=${window.location.hostname}`;
}

export function getCookieByName(cname) {
  if (!isDocumentDefined) {
    return "";
  }
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookieByName(name) {
  document.cookie =
    name +
    `=; Path=/; domain=${window.location.hostname}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie =
    name +
    `=; Path=/; domain=.gatsbyjs.io; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}
