import React from 'react'

import {
  TTF_ADMIN_HOST
} from '@constants/config'

export const EventsLink = (props) => {
  const {ContentElement='Manage', target='__blank'} = props
  return (
    <a href={`${TTF_ADMIN_HOST}/events`} target={target}>{ContentElement}</a>
  )
}

export const NewEventCreateLink = (props) => {
  const {ContentElement='Create', target='__blank'} = props
  return (
    <a href={`${TTF_ADMIN_HOST}/events/new`} target={target}>{ContentElement}</a>
  )
}

export const BrandsLink = (props) => {
  const {ContentElement='Manage', target='__blank'} = props
  return (
    <a href={`${TTF_ADMIN_HOST}/brands`} target={target}>{ContentElement}</a>
  )
}

export const NewBrandCreateLink = (props) => {
  const {ContentElement='Create', target='__blank'} = props
  return (
    <a href={`${TTF_ADMIN_HOST}/brands/new`} target={target}>{ContentElement}</a>
  )
}

export const DraftEventsManageLink = (props) => {
  const {ContentElement='Manage Draft Events', target='__blank'} = props
  return (
      <a href={`${TTF_ADMIN_HOST}/draft-events`} target={target}>{ContentElement}</a>
  )
}