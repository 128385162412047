import { schema } from "normalizr";
export { default as DEFAULT_AVATAR } from "@assets/image/default-avatar.png";

export const RENDER_TAGS_COUNT = 3;

// TODO: DEFINE REAL GRADES
export const EVENT_GRADE_LANDING_CAROUSEL = 250;
export const EVENT_GRADE_FEATURED = 200;
export const EVENT_GRADE_RECOMMENDED = 100;
export const EVENT_GRADE_MAX = 299;

// Display maxes
export const EVENT_CAROUSEL_MAX = 16;

//Pagination
export const EVENTS_PAGINATION_SIZE = 24;

// EVENT STATES
export const EVENT_STATE_EDIT = "edit";
export const EVENT_STATE_PUBLISHED = "published";
export const EVENT_STATE_EXPIRED = "expired";

// EVENT REVIEW ACTIONS
export const EVENT_REVIEW_RECOMMEND = "recommend";
export const EVENT_REVIEW_UNRECOMMEND = "unrecommend";

// USER ROLES
export const USER_CURATOR = "curator";

// SCHEMAS
const neighborhood = new schema.Entity(
  "neighborhoods",
  {},
  { idAttribute: "value" }
);
export const REGION_SCHEMA = new schema.Entity(
  "regions",
  {
    neighborhoods: [neighborhood],
  },
  { idAttribute: "value" }
);

const subcategory = new schema.Entity(
  "subcategories",
  {},
  { idAttribute: "value" }
);
export const CATEGORY_SCHEMA = new schema.Entity(
  "categories",
  {
    subcategories: [subcategory],
  },
  { idAttribute: "value" }
);

export const USER_SCHEMA = new schema.Entity("users");

let blastUrl;
if (process.env.NODE_ENV === "local") {
  blastUrl = "http://localhost:3030/api/";
} else if (process.env.NODE_ENV === "development") {
  // blastUrl = 'https://ucvr.co/api/';
  blastUrl = "https://viablast.co/api/";
} else {
  blastUrl = "https://viablast.co/api/";
}

export const BLAST_API_URL = blastUrl;
export const TICKET_FAIRY_URL = process.env.GATSBY_TTF_PUBLIC_API_BASE;
export const TICKET_FAIRY_CONSUMER_HOST =
  process.env.GATSBY_TTF_PUBLIC_API_BASE;
export const TTF_ADMIN_HOST = process.env.GATSBY_TTF_ADMIN;
export const X_SOURCE_ORIGIN =
  process.env.GATSBY_TTF_PUBLIC_X_SOURCE_ORIGIN || "www.restlessnites.com";
export const IS_LIVE = X_SOURCE_ORIGIN === "www.restlessnites.com";
export const SUPPORT_EMAIL = "support@restlessnites.com";
export const ZENDESK_KEY = process.env.GATSBY_TTF_ZENDESK;
export const BRAND = "restlessnites";

// GA constants
export const GA = {
  PAGES: {
    curators: "curators",
    events: "events",
    search: "search",
    editorial: "editorial",
    page: "page",
    tickets: "tickets",
    signup: "signup",
    login: "login",
    forgot_password: "forgot-password",
    reset_password: "reset-password",
  },

  CATEGORY: {
    filter: "filter",
    purchase: "purchase",
  },

  EVENTS: {
    location_filter: "location_filter",
    category_filter: "category_filter",
    date_filter: "date_filter",
    all_events: "all_events",
    signup: "signup",
    login: "login",
    forgot_password: "forgot-password",
    reset_password: "reset-password",
  },

  ACTIONS: {
    purchase_initiated: "purchase_initiated",
    click: "click",
    select: "select",
    deselect: "deselect",
  },
};

export const NEW_CHECKOUT_FLOW_DATE =
  process.env.REACT_APP_NEW_CHECKOUT_FLOW_DATE;

// Boost Event Section constants
export const PROMOTE_WITH_URL = "https://www.promotewith.restlessnites.com";
export const TICKET_WITH_URL = "https://www.ticketwith.restlessnites.com";

// META TAGS
export const META = {
  title: "Restless Nites",
  description:
    "Restless Nites is a tightly–curated list of crucial events based in Los Angeles & New York, and launching soon in San Francisco, Portland, and beyond. We aim to expose the best that nightlife has to offer, to a wider audience of music heads, art lovers, film buffs, and fashion freaks.",
  keywords: "restlessnites, restless, nites, LA DJ, Los Angeles music",
  author: "Restless Nites",
  locale: "en_US",
  url: "https://restlessnites.com",
  image: "https://restlessnites.com/static/media/rn-logo.jpg",
  type: "website",
  "fb:app_id": "740973099418151",
};

export const RN_API = process.env.GATSBY_TTF_RN_API;

export const AccessSubListings = [
  {
    value: "Access Passes",
    label: "Access Events",
    selected: false,
    options: [],
  },
  {
    value: "Bonus Passes",
    label: "Access Reserve",
    selected: false,
    options: [],
  },
];
