import React from "react";
import { connect } from "react-redux";
import { Link } from "gatsby";

class _MeLink extends React.Component {
  render() {
    const { me: { username, rnRoles } = {} } = this.props;
    const linkTo = rnRoles.includes("curator")
      ? `/u/${username}`
      : `/u/${username}`;

    return <Link to={linkTo}>View</Link>;
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.me.data,
  };
};

export const MeLink = connect(mapStateToProps, undefined)(_MeLink);

export const ImportFbEventLink = (props) => {
  const { ContentElement = "Import from FB" } = props;
  return <a href={`/events/fbImport`}>{ContentElement}</a>;
};

export const CreatePageLink = (props) => {
  const { ContentElement = "Submit" } = props;
  return <Link to={`/page/submit`}>{ContentElement}</Link>;
};

export const ManagePagesLink = (props) => {
  const { ContentElement = "Manage" } = props;
  return <Link to={`/page/manage`}>{ContentElement}</Link>;
};

export const CreatePostLink = (props) => {
  const { ContentElement = "Submit" } = props;
  return <Link to={`/editorial/submit`}>{ContentElement}</Link>;
};

export const ManagePostsLink = (props) => {
  const { ContentElement = "Manage" } = props;
  return <Link to={`/editorial/manage`}>{ContentElement}</Link>;
};
