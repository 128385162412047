import React, { PureComponent } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import LoaderOverlay from "../components/global/loader-overlay";
import { getCookieByName } from "../utils/cookies";

export const privateRouter = (Container, roles = []) => {
  const mapStateToProps = (state) => ({
    me: state.auth.me,
  });

  return connect(mapStateToProps)(
    class extends PureComponent {
      render() {
        const {
          me: { exist, checked, loading, data },
        } = this.props;
        const xtfCookie = getCookieByName("X-TF-ECOMMERCE");
        if (checked && !exist && !xtfCookie) {
          return <Redirect to="/" />;
        }
        if (loading && !checked) {
          return <LoaderOverlay />;
        }
        if (exist) {
          const { rnRoles = [] } = data;
          if (
            roles.length &&
            !rnRoles.some(Array.prototype.includes.bind(roles))
          ) {
            return <Redirect to="/" />;
          }
          return <Container {...this.props} />;
        }
        return null;
      }
    }
  );
};
