import React, { Component } from "react";
import { connect } from "react-redux";
import { configureHeader } from "../../actions/ui";
import { ProfileEditForm } from ".";
import ModalHeader from "../../components/modals/ModalHeader";
import { privateRouter } from "../../hoc";

class ProfileEditBlock extends Component {
  componentDidMount = () => {
    this.props.configureHeader({
      headerVisibilityLocked: true,
    });
    window && window.scrollTo(0, 0);
  };

  render() {
    const {
      auth: {
        me: { data },
      },
    } = this.props;
    return (
      <div className="modal-container">
        <ModalHeader title="Configure Your Profile Page" />
        <div className="modal-content">
          <ProfileEditForm profile={data} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
  };
};

export default privateRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileEditBlock)
);
