import update from "immutability-helper";
import {
  GET_COUNTRIES_START,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
} from "../constants/action-types";

const initialState = {
  countries: {
    data: {
      countriesEnum: {},
      countriesOptions: [],
    },
    exist: false,
    checked: false,
    loading: false,
  },
};

function shared(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES_START: {
      const updates = {
        countries: {
          $merge: {
            loading: true,
            exist: false,
            checked: false,
            data: [],
          },
        },
      };
      return update(state, updates);
    }

    case GET_COUNTRIES_SUCCESS: {
      const { data = [] } = action.payload;
      const updates = {
        countries: {
          $merge: {
            loading: false,
            exist: true,
            checked: true,
            data,
          },
        },
      };
      return update(state, updates);
    }

    case GET_COUNTRIES_ERROR: {
      const updates = {
        countries: {
          $merge: {
            loading: false,
            exist: false,
            checked: true,
          },
        },
      };
      return update(state, updates);
    }

    default: {
      return state;
    }
  }
}

export default shared;
