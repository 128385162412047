import React, { Component } from "react";
import { connect } from "react-redux";
import { Intent } from "@blueprintjs/core";
import { AccountEditForm } from ".";
import { privateRouter } from "../../hoc";
import { updateAccount } from "../../actions/dashboard/account";
import { configureHeader, showToast, removeModal } from "../../actions/ui";
import ModalHeader from "../../components/modals/ModalHeader";

class AccountEdit extends Component {
  componentDidMount = () => {
    this.props.configureHeader({
      headerVisibilityLocked: true,
    });
    window && window.scrollTo(0, 0);
  };

  onSubmit = async (values) => {
    const { updateAccount, showToastError, showToastSuccess, removeModal } =
      this.props;
    try {
      await updateAccount(values);
      showToastSuccess("Success!");
      removeModal("account_edit");
    } catch (error) {
      const { isAxiosError, response } = error;
      let error_message = "Something Wrong";
      if (isAxiosError && response && response.status === 422) {
        const { data: { message = "" } = {} } = response;
        if (message) {
          error_message = message;
        }
      }
      showToastError(error_message);
      throw error;
    }
  };

  render() {
    const {
      auth: {
        me: { data },
      },
    } = this.props;
    return (
      <div className="modal-container">
        <ModalHeader title="Edit Your Account" />
        <div className="modal-content">
          <AccountEditForm account={data} onSubmit={this.onSubmit} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    updateAccount: (data) => {
      return dispatch(updateAccount(data));
    },
    showToastError: (message) => {
      return dispatch(showToast(message));
    },
    showToastSuccess: (message) => {
      return dispatch(showToast(message, Intent.SUCCESS));
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
  };
};

export default privateRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountEdit)
);
