var HAS_FULLSCREEN = (function () {
  const isDocumentDefined = typeof document !== "undefined";
  if (isDocumentDefined) {
    var element = document.body;
    return !!(
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullscreen
    );
  }
  return false;
})();

module.exports = HAS_FULLSCREEN;
