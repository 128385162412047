import axios from "axios";
import {
  TICKET_FAIRY_CONSUMER_HOST,
  X_SOURCE_ORIGIN,
  IS_LIVE,
} from "@constants/config";
import {
  deleteCookieByName,
  getCookieByName,
  setCustomCookie,
} from "../../utils/cookies";
import _get from "lodash/get";
import { store } from "../../../wrap-with-provider";
import { handleLogOut } from "../../actions/auth/auth-logout";

const isWindowDefined = typeof window !== "undefined";

const ttfHeaders = {
  Accept: "application/vnd.api+json",
  "Content-Type": "application/vnd.api+json",
};

if (!IS_LIVE) {
  ttfHeaders["X-Source-Origin"] = X_SOURCE_ORIGIN;
}

if (isWindowDefined && window?.localStorage.getItem("auth_guest_token")) {
  ttfHeaders["Authorization-Guest"] =
    window?.localStorage.getItem("auth_guest_token");
}

// without /api
export const ttfRequest = axios.create({
  baseURL: TICKET_FAIRY_CONSUMER_HOST,
  withCredentials: true,
  headers: ttfHeaders,
});

// preview1.
export const ttfPrivateRequest = axios.create({
  baseURL: `${TICKET_FAIRY_CONSUMER_HOST}/api`,
  withCredentials: true,
  headers: ttfHeaders,
});

ttfRequest.interceptors.request.use((config) => {
  const guestToken =
    isWindowDefined && window?.localStorage.getItem("auth_guest_token");

  if (guestToken) {
    const updatedHeaders = {
      ...config.headers,
      "Authorization-Guest": guestToken,
    };
    config.headers = updatedHeaders;
  }

  if (getCookieByName("X-TF-ECOMMERCE")) {
    const updatedHeaders = {
      ...config.headers,
      "X-TF-ECOMMERCE": getCookieByName("X-TF-ECOMMERCE"),
    };
    config.headers = updatedHeaders;
  }

  return config;
});

ttfRequest.interceptors.response.use((response) => {
  const xtfCookie = _get(response, "headers.x-tf-ecommerce");
  const url = _get(response, "config.url");
  const method = _get(response, "config.method");

  if (xtfCookie && !(url === "/auth" && method === "delete")) {
    setCustomCookie("X-TF-ECOMMERCE", xtfCookie);
  }

  return response;
});

ttfPrivateRequest.interceptors.request.use((config) => {
  const guestToken =
    isWindowDefined && window?.localStorage.getItem("auth_guest_token");

  if (guestToken) {
    const updatedHeaders = {
      ...config.headers,
      "Authorization-Guest": guestToken,
    };
    config.headers = updatedHeaders;
  }

  if (getCookieByName("X-TF-ECOMMERCE")) {
    const updatedHeaders = {
      ...config.headers,
      "X-TF-ECOMMERCE": getCookieByName("X-TF-ECOMMERCE"),
    };
    config.headers = updatedHeaders;
  }

  return config;
});

ttfPrivateRequest.interceptors.response.use(
  (response) => {
    const xtfCookie = _get(response, "headers.x-tf-ecommerce");
    const url = _get(response, "config.url");
    const method = _get(response, "config.method");

    if (xtfCookie && !(url === "/auth" && method === "delete")) {
      setCustomCookie("X-TF-ECOMMERCE", xtfCookie);
    }

    return response;
  },
  (error) => {
    const status = _get(error, "response.status");

    if (status === 401) {
      store.dispatch(handleLogOut());
      deleteCookieByName("X-TF-ECOMMERCE");
      isWindowDefined && window?.localStorage.removeItem("user_data");
    }

    throw error;
  }
);

ttfPrivateRequest.setGuestToken = (token) => {
  ttfPrivateRequest.defaults.headers.common["Authorization-Guest"] = token;
};

ttfRequest.setGuestToken = (token) => {
  ttfRequest.defaults.headers.common["Authorization-Guest"] = token;
};

export {
  getBrandBySlug,
  getUserBySlug,
  getCustomers,
  getCountries,
  postReferralVisits,
  // private
  getProfileNew,
  recommendEventPost,
  updateProfile,
  updateAccount,
  getCountryStates,
  signUpNewsletter,
} from "./to_ttf_requests";
