import { ttfPrivateRequest, ttfRequest } from ".";
import { get } from "lodash";
import { deleteCookieByName } from "../../utils/cookies";

// utils
import {
  brandAdapter,
  userAdapter,
  usersAdapter,
  profileAdapter,
  countriesAdapter,
} from "./utils";
import { ttfHeaders } from "../../actions/checkout";

const isWindowDefined = typeof window !== "undefined";

const setCustomHeader = (response) => {
  const guestHeaderValue = get(response, "headers.authorization-guest");
  if (guestHeaderValue) {
    isWindowDefined &&
      window?.localStorage.setItem("auth_guest_token", guestHeaderValue);
    ttfRequest.setGuestToken(guestHeaderValue);
    ttfPrivateRequest.setGuestToken(guestHeaderValue);
    ttfHeaders.push({ "Authorization-Guest": guestHeaderValue });
  }
};

export const removeGuestToken = () => {
  isWindowDefined && window?.localStorage.removeItem("auth_guest_token");
  isWindowDefined && window?.localStorage.removeItem("user_data");
  ttfRequest.setGuestToken("");
  ttfPrivateRequest.setGuestToken("");
  ttfHeaders.push({ "Authorization-Guest": "" });
};

export const ttfOauthLogin = async (formData, params) => {
  const res = await ttfPrivateRequest.post(`auth`, formData);
  setCustomHeader(res);
  return get(res, "data");
};

export const ttfRegistration = async (formData) => {
  const registrationformData = new FormData();
  registrationformData.set("first_name", formData.first_name);
  registrationformData.set("last_name", formData.last_name);
  registrationformData.set("email", formData.email);
  registrationformData.set("password", formData.password);
  registrationformData.set(
    "password_confirmation",
    formData.password_confirmation
  );
  registrationformData.set("client_id", process.env.GATSBY_TTF_CLIENT_ID);
  registrationformData.set(
    "client_secret",
    process.env.GATSBY_TTF_CLIENT_SECRET
  );
  registrationformData.set(
    "redirect_uri",
    `${window.location.origin}/ttf_handleauth`
  );

  const res = await ttfPrivateRequest.post(
    "v1/oauth/register-rn",
    registrationformData
  );
  setCustomHeader(res);
  return get(res, "data");
};

export const ttfForgotPassword = async (data) => {
  const res = await ttfPrivateRequest.post(
    "v1/oauth/restore-password-rn",
    data
  );
  setCustomHeader(res);
  return get(res, "data");
};

export const ttfResetPassword = async (data) => {
  const res = await ttfPrivateRequest.post("v1/oauth/reset-password-rn", data);
  return get(res, "data");
};

export const getBrandBySlug = async (slug) => {
  const res = await ttfPrivateRequest.get(`brand/${slug}/`);
  const brand = {
    ...get(res, "data.attributes", {}),
    id: get(res, "data.id", ""),
  };
  return brandAdapter(brand);
};

export const getUserBySlug = async (slug, params) => {
  const res = await ttfPrivateRequest.get(`user/${slug}/`, { params });
  const user = get(res, "data.data");
  return userAdapter(user);
};

export const getCustomers = async (params) => {
  const res = await ttfPrivateRequest.get(`customers/`, { params });
  const customers = get(res, "data.data");
  return usersAdapter(customers);
};

export const getCountries = async () => {
  const res = await ttfPrivateRequest.get(`countries/`);
  const data = get(res, "data.data");
  setCustomHeader(res);
  return countriesAdapter(data);
};

export const getCountryStates = async (countryId) => {
  const serverResult = await ttfPrivateRequest.get(
    `/countries/${countryId}/states/`
  );
  const states = get(serverResult, "data.data");
  return states;
};

export const postReferralVisits = async (eventId, referralId) => {
  const res = await ttfPrivateRequest.post(`v1/event/${eventId}/referrer/`, {
    referrer: `${referralId}`,
  });
  return res;
};

// private
export const getProfileNew = async () => {
  try {
    const res = await ttfPrivateRequest.get("customer/profile/");
    const profile = get(res, "data.data", {});
    return profileAdapter(profile);
  } catch (error) {
    const guestHeaderValue = get(error, "response.headers.authorization-guest");
    if (guestHeaderValue) {
      isWindowDefined &&
        window?.localStorage.setItem("auth_guest_token", guestHeaderValue);
    }

    if (get(error, "response.status") === 401) {
      deleteCookieByName("X-TF-ECOMMERCE");
      isWindowDefined && window?.localStorage.removeItem("user_data");
    }

    throw error;
  }
};

export const recommendEventPost = async (id) => {
  return await ttfPrivateRequest.post("/customer/recommend-event/", {
    data: {
      attributes: {
        eventId: id,
      },
    },
  });
};

export const updateProfile = async (data) => {
  const res = await ttfPrivateRequest.patch("/customer/profile/", {
    data: {
      attributes: data,
    },
  });
  const profile = get(res, "data.data", {});
  return profileAdapter(profile);
};

export const updateAccount = async (data) => {
  const res = await ttfPrivateRequest.patch("/customer/account/", {
    data: {
      attributes: data,
    },
  });
  const profile = get(res, "data.data", {});
  return profileAdapter(profile);
};

export const loadTtfBrand = async ({ ttf_brand_slug }) => {
  const res = await ttfRequest.get(`api/brand/${ttf_brand_slug}/`);
  setCustomHeader(res);
  const data = get(res, "data");
  return brandAdapter({ ...data.attributes, id: data.id });
};

export const logout = async () => {
  await ttfPrivateRequest.delete("/auth");
};

export const verifyEmailRequest = async (token) => {
  const res = await ttfPrivateRequest.post(`/v1/email_verification/${token}`);
  const data = get(res, "data", {});
  return data;
};

export const signUpNewsletter = async (body) => {
  return await ttfPrivateRequest.post("/brand/1201/relationships/newsletter", {
    data: {
      attributes: body,
    },
  });
};
