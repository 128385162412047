import { SEARCH_PAGINATE, CLEAR_SEARCH } from "@constants/action-types";

const initialState = {
  currentPage: 0,
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case SEARCH_PAGINATE: {
      return Object.assign({}, state, {
        currentPage: state.currentPage + 1,
      });
    }
    case CLEAR_SEARCH: {
      return Object.assign({}, state, {
        currentPage: 0,
      });
    }
    default: {
      return state;
    }
  }
}
