import { AUTH_USER_GET } from "../../constants/action-types";

import { getProfileNew } from "../../services/http_services";

const isWindowDefined = typeof window !== "undefined";

export function authUser() {
  const fetchProfile = async () => {
    const profile = await getProfileNew();
    isWindowDefined &&
      window?.localStorage.setItem("user_data", JSON.stringify(profile));
    return { data: profile };
  };
  return {
    type: AUTH_USER_GET,
    payload: {
      promise: fetchProfile(),
    },
  };
}
