import { LOAD_BLAST_EVENT, PRELOAD_BLAST_EVENT } from "@constants/action-types";

import { BLAST_API_URL } from "@constants/config";
import fetch from "@utils/fetch";
import { showToast } from "@actions/ui";

const EVENT_STATE_EDIT = "edit";
const EVENT_STATE_PUBLISHED = "published";
const EVENT_STATE_SUSPENDED = "suspended";
const EVENT_STATE_EXPIRED = "expired";

function _preloadBlastEvent(id) {
  return {
    type: PRELOAD_BLAST_EVENT,
    payload: {
      promise: fetch(
        BLAST_API_URL +
          "event/?rn_id=" +
          id +
          "&event_state=" +
          EVENT_STATE_EDIT +
          "&event_state=" +
          EVENT_STATE_PUBLISHED +
          "&event_state=" +
          EVENT_STATE_EXPIRED +
          "&event_state=" +
          EVENT_STATE_SUSPENDED,
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then((response) => {
        if (response.status === 200 || response.status === 304) {
          return response.json().then((d) => {
            return {
              data: d,
              status: response.status,
            };
          });
        }

        return {
          data: [],
          status: response.status,
        };
      }),
    },
  };
}

function _loadBlastEvent(id) {
  return {
    type: LOAD_BLAST_EVENT,
    payload: {
      promise: fetch(BLAST_API_URL + "event/" + id, {
        method: "GET",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=utf-8",
        },
      }).then((response) => {
        if (response.status === 200 || response.status === 304) {
          return response.json().then((d) => {
            return {
              data: d,
              status: response.status,
            };
          });
        }

        return {
          data: {},
          status: response.status,
        };
      }),
    },
  };
}

export function loadBlastEvent(id) {
  return function (dispatch, getState) {
    let rnID;
    if (getState().eventSubmission.submissionType === "custom") {
      rnID = getState().eventSubmission.customData.id;
    } else if (getState().eventSubmission.submissionType === "fb") {
      rnID = getState().eventSubmission.fbImportData.id;
    }

    return dispatch(_preloadBlastEvent(id || rnID))
      .catch((e) => {
        dispatch(showToast("Could not load ticketing. Please reload browser!"));
      })
      .then((res) => {
        if (
          res &&
          res.value &&
          res.value.status !== 200 &&
          res.value.status !== 304
        ) {
          return dispatch(
            showToast("Could not load ticketing. Please reload browser!")
          );
        }

        return res;
      })
      .then((res) => {
        if (res && res.value && res.value.data && res.value.data.length > 0) {
          if (res.value.data[0].id) {
            dispatch(_loadBlastEvent(res.value.data[0].id));
          }
        }
      });
  };
}
