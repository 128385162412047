import _get from "lodash/get";
import { brandAdapter } from "../../services/http_services/utils.js";
import { baseRequest, clientRequest } from "./config.js";

const isWindowDefined = typeof window !== "undefined";

export const getEvent = async (slug, previewKey) => {
  try {
    const headers = {};
    if (isWindowDefined) {
      headers["authorization-guest"] =
        window?.localStorage.getItem("auth_guest_token");
    }

    const res = await baseRequest.get(
      `/event/${slug}/?format=json&recommenders_type=curator`,
      {
        params: {
          pk: previewKey,
        },
        headers,
      }
    );
    const data = _get(res, "data");
    return data;
  } catch (e) {
    throw e;
  }
};

export const getBrand = async (slug) => {
  const res = await baseRequest.get(`/api/brand/${slug}/`);
  const data = _get(res, "data");
  return brandAdapter({ ...data.attributes, id: data.id });
};

const eventsAdapterVersionOne = (eventsResponse) => {
  const events = eventsResponse.map(({ attributes: event, attributes: { venue }, id }) => {
    const rn_event_metadata = event.rnMetadata;
    const eventCategory = rn_event_metadata?.eventCategory;
    const eventGenres = rn_event_metadata?.eventGenres;
    const tags = [];
    if (eventCategory) {
      for (const key in eventCategory) {
        if (eventCategory[key]) {
          tags.push(key);
        }
      }
    }
    if (eventGenres) {
      for (const key in eventGenres) {
        if (eventGenres[key]) {
          tags.push(key);
        }
      }
    }
    tags.push(rn_event_metadata?.region);
    return {
      brand_slug: event.brandSlug,
      event_state: "published",
      event_grade: rn_event_metadata?.eventGrade || 0,
      isAccessFeatured: rn_event_metadata?.isAccessFeatured,
      accessSubGroup: rn_event_metadata?.accessSubGroup,
      fb_url: event.facebookEvent,
      private: rn_event_metadata?.flagPrivate,
      rsvp_url: rn_event_metadata?.rsvpUrl,
      brief: rn_event_metadata?.brief,
      description: event.description,
      start_time: event.startDate.replace("Z", ""),
      end_time: event.endDate,
      name: event.displayName,
      region: rn_event_metadata?.region,
      slug: event.slug,
      tags: tags.join(","),
      flyerImage: event.flyerImage,
      bannerImage: event.bannerImage,
      id,
      ttf_slug: event.slug,
      flagHidden: venue?.flagHidden,
      timezone: event.timezone,
      metadata: { rn_event_metadata },
      place: {
        location: {
          city: venue?.city,
          state: venue?.state,
          street: venue?.street,
          zip: venue?.postalCode,
        },
        name: venue?.displayName,
      },
    };
  });
  return events.filter((event) => {
    return !event.metadata.rn_event_metadata.flagPrivate;
  });
};

export const getEventsVersionOne = async (params) => {
  try {
    const res = await clientRequest.get(`api/v1/events/upcoming`, {
      params,
    });
    const data = _get(res, "data", []);
    return {
      events: eventsAdapterVersionOne(data),
      length: data.length,
    };
  } catch (e) {
    throw e;
  }
}

const eventsAdapter = (eventsResponse) => {
  const events = eventsResponse.map(({ event, venue }) => {
    const rn_event_metadata = JSON.parse(event.metadata.rn_event_metadata);
    const eventCategory = rn_event_metadata?.eventCategory;
    const eventGenres = rn_event_metadata?.eventGenres;
    const tags = [];
    if (eventCategory) {
      for (const key in eventCategory) {
        if (eventCategory[key]) {
          tags.push(key);
        }
      }
    }
    if (eventGenres) {
      for (const key in eventGenres) {
        if (eventGenres[key]) {
          tags.push(key);
        }
      }
    }
    tags.push(rn_event_metadata?.region);
    return {
      brand_slug: event.brand_slug,
      event_state: "published",
      event_grade: rn_event_metadata?.eventGrade || 0,
      isAccessFeatured: rn_event_metadata?.isAccessFeatured,
      accessSubGroup: rn_event_metadata?.accessSubGroup,
      fb_url: event.facebook_event,
      private: rn_event_metadata?.flagPrivate,
      rsvp_url: rn_event_metadata?.rsvpUrl,
      brief: rn_event_metadata?.brief,
      description: event.description,
      start_time: event.start_date,
      end_time: event.end_date,
      name: event.name,
      region: rn_event_metadata?.region,
      slug: event.slug,
      tags: tags.join(","),
      flyerImage: event.flyer_image,
      bannerImage: event.banner_image,
      id: event.id,
      ttf_slug: event.slug,
      flagHidden: venue?.venue_hidden,
      timezone: event.timezone,
      metadata: { rn_event_metadata },
      place: {
        location: {
          city: venue?.city,
          state: venue?.state,
          street: venue?.street,
          zip: venue?.postal_code,
        },
        name: venue?.name,
      },
    };
  });
  return events.filter((event) => {
    return !event.metadata.rn_event_metadata.flagPrivate;
  });
};

export const getEvents = async (slug) => {
  try {
    const params = {
      compact: "event-list",
    };
    const res = await baseRequest.get(`events/${slug}?format=json`, {
      params,
    });
    const data = _get(res, "data.events", []);
    return eventsAdapter(data);
  } catch (e) {
    throw e;
  }
}
