import React, { Component } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import {
  toggleNav,
  toggleTicketingOverlay,
  createModal,
  removeModal,
} from "../actions/ui";
import { loadBlastEvent } from "../actions/load-blast-event";
import MenuBar from "../containers/global/menu-bar";
import LocationDetector from "../containers/global/location-detector";
import AuthValidator from "../containers/auth/auth-validator";
import ModalServices from "../containers/global/ModalServices";
import LegacyScreen from "../containers/global/legacy-screen";
import LoaderOverlay from "../components/global/loader-overlay";
import MobileMenuOverlay from "../components/global/mobile-menu-overlay";
import { currentLocation, getActiveLocations } from "../selectors/filters";
import ScrollToTop from "../components/scrollToTop";
import { isWindowDefined } from "../assets/third-party/blast";
import "../styles/main.scss";
import { loadPaginationEvents, loadPaginationEventsCarousel, loadPaginationEventsCarouselStart, loadPaginationEventsError, loadPaginationEventsStart, loadPaginationEventsSuccess } from "../actions/load-events";
import { EVENTS_PAGINATION_SIZE, EVENT_GRADE_LANDING_CAROUSEL } from "../constants/config";
import { getEventsVersionOne } from "../utils/http_service/events";
const GlobalToaster = React.lazy(() =>
  import("../containers/global/global-toaster")
);

class App extends Component {
  interruptor = 0
  componentDidMount() {
    if (isWindowDefined) {
      const standalone = window?.navigator.standalone;
      const userAgent = window?.navigator.userAgent.toLowerCase();
      const safari = /safari/.test(userAgent);
      const ios = /iphone|ipod|ipad/.test(userAgent);

      this.isUIWebView = false;
      if (ios) {
        if (!standalone && !safari) {
          this.isUIWebView = true;
        }
      }

      this.addWidgetCallbackHandlers();

      const ua =
        window?.navigator.userAgent ||
        window?.navigator.vendor ||
        window?.opera;
      this.isFacebookApp = ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;

      window.prerenderReady = true;
    }

    const interval = setInterval(async () => {
      const currLocation = _get(this.props.currentRegion, 'value', null)
      if (currLocation) {
        clearInterval(interval)
        this.props.loadPaginationEventsStart()
        this.fetchEventsRecursively(currLocation, this.interruptor, 0)
      }
    }, 100)
    const intervalCarousel = setInterval(async () => {
      const currLocation = _get(this.props.currentRegion, 'value', null)
      if (currLocation) {
        clearInterval(intervalCarousel)
        this.fetchEventsRecursivelyCarousel(currLocation, 0, this.interruptor)
      }
    }, 100)
  }

  fetchEventsRecursively = async (currLocation, interruptor, page) => {
    try {
      const { events, length } = await getEventsVersionOne({
        rn_filters: {
          region: currLocation
        },
        size: EVENTS_PAGINATION_SIZE,
        page
      })
      if (this.interruptor !== interruptor) {
        this.props.loadPaginationEventsSuccess()
        return
      }
      this.props.loadPaginationEvents(events)
      if (length < EVENTS_PAGINATION_SIZE) {
        return
      }
      this.fetchEventsRecursively(currLocation, interruptor, page + 1)
    } catch (e) {
      this.props.loadPaginationEventsError()
    }
  }

  fetchEventsRecursivelyCarousel = async (currLocation, page, interruptor) => {
    try {
      const { events, length } = await getEventsVersionOne({
        rn_filters: {
          region: currLocation,
          grade: EVENT_GRADE_LANDING_CAROUSEL
        },
        size: EVENTS_PAGINATION_SIZE,
        page
      })
      if (this.interruptor !== interruptor) {
        return
      }
      this.props.loadPaginationEventsCarousel(events)
      if (length < EVENTS_PAGINATION_SIZE) {
        return
      }
      this.fetchEventsRecursivelyCarousel(currLocation, page + 1, interruptor)
    } catch (e) { }
  }

  removeElement = (element) => {
    element && element.parentNode && element.parentNode.removeChild(element);
  };

  componentDidUpdate = prevProps => {
    const {
      auth: {
        me: { checked }
      }
    } = this.props
    if (
      checked &&
      !this.props.loading &&
      this.props.contentStatus === 200 &&
      this.didLocationUpdate(prevProps)
    ) {
      this.interruptor = this.interruptor + 1
      const currLocation = _get(this.props.currentRegion, 'value', null)
      this.props.loadPaginationEventsStart()
      this.fetchEventsRecursively(currLocation, this.interruptor, 0)
      this.props.loadPaginationEventsCarouselStart()
      this.fetchEventsRecursivelyCarousel(currLocation, 0, this.interruptor)
    }
  }

  didLocationUpdate = prevProps => {
    if (this.props.locationFilters && this.props.locationFilters.length > 0) {
      const { currentRegion } = this.props
      const prevLocation = prevProps.currentRegion

      const currLocation = currentRegion

      if (
        prevLocation !== undefined &&
        currLocation !== undefined &&
        prevLocation.value !== currLocation.value
      ) {
        return true
      }

      return false
    }

    return false
  }

  isUserLocationSet = () => {
    return typeof this.props.currentRegion === 'object'
  }

  addWidgetCallbackHandlers = () => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(
      messageEvent,
      (e) => {
        if (e.data === "close" && document.getElementById("blast-widget")) {
          if (this.props.platform.IS_MOBILE) {
            document.body.classList.remove("blast-fix-body");
            document.body.scrollTop = window.blastScrollY;
          }
          this.removeElement(document.getElementById("blast-widget-container"));
        }

        if (e.data === "blastTicketingDidMount") {
          document.body.classList.add("blast-fix-body");
        }

        if (
          e.data === "closeBlastTicketing" &&
          document.getElementById("blast-ticketing-overlay")
        ) {
          document.body.classList.remove("blast-fix-body");
          this.props.toggleTicketingOverlay(false);
          setTimeout(() => {
            window.scroll(0, window.blastScrollY);
          }, 50);
        }

        if (e.data === "blastTicketingCreated") {
          this.props.loadBlastEvent();
        }
      },
      false
    );
  };

  render() {
    const {
      children,
      auth,
      initialLocation,
      removeModal,
      createModal,
      browser,
    } = this.props;
    const {
      me: { checked, exist, data },
    } = auth;

    const role = _get(data, "rnRoles", []);

    const legacy = isWindowDefined
      ? {
        isLegacy: !!(
          this.props.platform.IS_IE ||
          /Edge\/\d./i.test(window?.navigator.userAgent)
        ),
        browser: this.props.platform.IS_IE
          ? "Internet Explorer"
          : "Microsoft Edge",
      }
      : {};

    const isSSR = typeof window === "undefined";

    return (
      <div
        className={`${this.props.platform.HAS_TOUCH ? "touch" : "no-touch"} ${this.isUIWebView ? "uiwebview" : ""
          } ${this.isFacebookApp ? "facebookApp" : ""} ${this.props.platform.IS_FIREFOX ? "firefox" : ""
          }`}
      >
        {legacy.isLegacy && <LegacyScreen browser={legacy.browser} />}
        {!!this.props.locationFilters.length && checked && <LocationDetector />}
        <AuthValidator location={this.props.location} />
        <ModalServices />
        <MenuBar
          loggedIn={exist}
          role={role}
          links={[]}
          removeModal={removeModal}
          createModal={createModal}
          browser={browser}
        />
        <MobileMenuOverlay
          auth={auth}
          loggedIn={exist}
          navOpen={this.props.navOpen}
          toggleNav={this.props.toggleNav}
          headerTheme={this.props.headerTheme}
          location={this.props.location}
          removeModal={removeModal}
          createModal={createModal}
          browser={browser}
        />
        {initialLocation && !legacy.isLegacy && (
          <div id="content">
            <>
              <ScrollToTop />
              {children}
            </>
          </div>
        )}
        {isSSR ? (
          <div />
        ) : (
          <React.Suspense fallback={<div />}>
            <GlobalToaster />
          </React.Suspense>
        )}
        {this.props.showLoader && <LoaderOverlay />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLoader: state.ui.showLoader,
    auth: state.auth,
    initialLocation: state.eventFilters.initialLocationDefined,
    locationFilters: getActiveLocations(state),
    navOpen: state.ui.navOpen,
    headerTheme: state.ui.headerTheme,
    platform: state.platform,
    orientation: state.browser.orientation,
    browser: state.browser,
    currentRegion: currentLocation(state),
    content: state.events.data,
    contentStatus: state.events.status,
    optimisticRecos: state.events.optimisticRecos,
    loading: state.events.hasStarted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNav: () => {
      dispatch(toggleNav());
    },
    toggleTicketingOverlay: (show) => {
      dispatch(toggleTicketingOverlay(show));
    },
    loadBlastEvent: () => {
      dispatch(loadBlastEvent(undefined));
    },
    removeModal: (id) => {
      dispatch(removeModal(id));
    },
    createModal: (modal, close_others) => {
      dispatch(createModal(modal, close_others));
    },
    loadPaginationEvents: (payload) => {
      dispatch(loadPaginationEvents(payload))
    },
    loadPaginationEventsSuccess: () => {
      dispatch(loadPaginationEventsSuccess())
    },
    loadPaginationEventsStart: () => {
      dispatch(loadPaginationEventsStart())
    },
    loadPaginationEventsError: () => {
      dispatch(loadPaginationEventsError())
    },
    loadPaginationEventsCarousel: (payload) => {
      dispatch(loadPaginationEventsCarousel(payload))
    },
    loadPaginationEventsCarouselStart: () => {
      dispatch(loadPaginationEventsCarouselStart())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
