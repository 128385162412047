export const brandAdapter = (brand = {}) => {
  const {
    description = "",
    email = "",
    city = "",
    shortDescription = "",
    logo = "",
    slug = "",
    displayName = "",
    id,
  } = brand;

  return {
    id,
    bio: description || "",
    shortBio: shortDescription || "",
    displayName: displayName || "",
    email,
    // followers: [],
    // following: [],
    logo,
    region: (city || "").toLowerCase(),
    // stats: {
    //   followers: 0,
    //   following: 0,
    // },
    slug,
  };
};

export const brandsAdapter = (brands = []) => brands.map(brandAdapter);

export const userAdapter = (user = {}) => {
  const {
    username = "",
    bio = "",
    shortBio = "",
    image = "",
    firstName = "",
    lastName = "",
    email = "",
    recommendedEvents = [],
    region = "",
    id,
    rnRoles = [],
    screenName,
  } = user;

  const fullName = `${firstName} ${lastName}`;
  return {
    username,
    bio: bio || "",
    shortBio: shortBio || "",
    image,
    fullName,
    firstName,
    lastName,
    email,
    recommendedEvents,
    region: (region || "").toLowerCase(),
    id,
    rnRoles,
    screenName,
  };
};

export const usersAdapter = (users = []) => users.map(userAdapter);

export const profileAdapter = (user = {}) => {
  const {
    email = "",
    firstName = "",
    lastName = "",
    recommendedEvents = [],
    bio = "",
    shortBio = "",
    image = "",
    region = "",
    username = "",
    id,
    phone = "",
    zipCode = "",
    stateId = "",
    city = "",
    countryId = "",
    rnRoles = [],
    screenName = "",
  } = user;

  const fullName = `${firstName} ${lastName}`;
  return {
    username,
    bio: bio || "",
    shortBio: shortBio || "",
    image,
    fullName,
    firstName,
    lastName,
    email,
    recommendedEvents,
    region: (region || "").toLowerCase(),
    id,
    countryId,
    phone,
    zipCode,
    stateId,
    city,
    rnRoles,
    screenName,
  };
};

export const countriesAdapter = (data = {}) => {
  const countriesEnum = data;
  const countriesOptions = Object.entries(countriesEnum).map(
    ([value, label]) => ({ value, label })
  );
  return {
    countriesEnum,
    countriesOptions,
  };
};
