import React, { Component } from 'react';
import { SUPPORT_EMAIL } from '@constants/config';

class LoaderOverlay extends Component {

  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      error: false
    };
  }

  componentDidMount = () => {
    this.timer = setTimeout(() => {
      this.setState({
        error: true
      });
    }, 15000);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <div className={`loader-overlay ${this.state.error ? 'error' : ''}`}>
        { this.state.error &&
          <div className="error-msg">
            {'Sorry, something went wrong. Please reload the page!'}
            <span className="error-msg-report"><a href={`mailto:${SUPPORT_EMAIL}?subject=Issue%20Report`}>Report this issue</a></span>
          </div>
        }
      </div>
    );
  }
}


export default LoaderOverlay;
