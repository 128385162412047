import React, { Component } from "react";
import { connect } from "react-redux";
import { configureHeader } from "../../actions/ui";
import { ProfileEditForm } from ".";
import Footer from "../../components/global/footer/footer";
import { privateRouter } from "../../hoc";

class ProfileEdit extends Component {
  componentDidMount = () => {
    this.props.configureHeader({
      headerVisibilityLocked: true,
    });
    window && window.scrollTo(0, 0);
  };

  render() {
    const {
      auth: {
        me: { data },
      },
    } = this.props;
    return (
      <div className="user-edit-container">
        <div className="container container__content">
          <div className="row">
            <div className="col s12">
              <div className="heading heading--primary">
                <h1 className="heading__headline">
                  Configure Your Profile Page
                </h1>
                <span className="heading__divider" />
              </div>
            </div>
          </div>
          <div className="row row--edit-user">
            <div className="col s8 offset-s2">
              <ProfileEditForm profile={data} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
  };
};

export default privateRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileEdit)
);
