import {
  SET_CHECKOUT_DATA,
  CLEAR_CHECKOUT_DATA,
} from "../constants/action-types";
import { IS_LIVE, X_SOURCE_ORIGIN } from "../constants/config";

export const ttfHeaders = [];

if (!IS_LIVE) {
  ttfHeaders["X-Source-Origin"] = X_SOURCE_ORIGIN;
}

export function setCheckoutData(data) {
  return {
    type: SET_CHECKOUT_DATA,
    payload: { data },
  };
}

export function clearCheckoutData() {
  return {
    type: CLEAR_CHECKOUT_DATA,
  };
}
