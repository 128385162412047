/*
Code ruthlessly copied from: https://github.com/JWTappert/use-zendesk-widget 
... to avoid loading another npm module.
*/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import _some from "lodash/some";
import Zendesk from "@assets/svg/svgui/zendesk-help.svg";
import { isWindowDefined } from "../../assets/third-party/blast";

export default function useZendeskWidget(key, options) {
  useEffect(() => {
    const zendeskInitScript = document.createElement("script");
    zendeskInitScript.id = "ze-snippet";
    zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    zendeskInitScript.async = true;
    document.body.appendChild(zendeskInitScript);
    window.zESettings = { ...options };
    return () => {
      document.body.removeChild(zendeskInitScript);
    };
  }, []);
}

const canUseDOM = () => {
  return !(
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  );
};

export const ZendeskWidget = (...args) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userData = useSelector((state) => _get(state, "auth.me") || {});

  const browser = useSelector((state) => state.browser);
  const isMobile = browser.lessThan.mediumSmall || browser.is.mediumSmall;

  const isSalesClosed = true;
  const eventSaleIsNotStarted = false;

  const tickets = useSelector((state) => state.tickets);
  const isTicketsEmpty = !(tickets && tickets.length);
  const isAllTicketsSoldOut = !_some(
    tickets,
    (item) => !(item.sold_out || item.soldOut)
  );

  const pathRegex = /events\/[a-zA-Z0-9]/;
  const isEventDetailsPage = isWindowDefined
    ? pathRegex.test(window.location.pathname)
    : false;
  const isEventsPage = isWindowDefined
    ? window.location.pathname === "/events" ||
      window.location.pathname === "/events/"
    : false;

  useEffect(() => {
    setIsLoggedIn(userData.exist && userData.checked);
  }, [userData]);

  if (canUseDOM && isWindowDefined && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk has not finished booting");
  }

  const onZendeskButtonClick = () => {
    window.zE("messenger", "open");
  };

  const getBottomPosition = () => {
    let bottomPosition = 16;
    if (isMobile) {
      if (isTicketsEmpty || isAllTicketsSoldOut) {
        bottomPosition = 120;
      }

      if (isSalesClosed) {
        bottomPosition = isLoggedIn ? 138 : 68;
      } else if (eventSaleIsNotStarted) {
        bottomPosition = 162;
      } else {
        bottomPosition = 88;
      }
    } else {
      if (isLoggedIn) {
        bottomPosition = 120;
      }
    }

    if (isEventsPage) {
      bottomPosition = 85;
    } else if (!isEventDetailsPage) {
      bottomPosition = 16;
    }

    return bottomPosition;
  };

  return (
    <div>
      <div className="zendesk_widget" style={{ bottom: getBottomPosition() }}>
        <i className="icon text-red" onClick={onZendeskButtonClick}>
          <span className="SVGInline">
            <Zendesk
              className="SVGInline-svg"
              style={{ width: 64, height: 64 }}
            />
          </span>
        </i>
      </div>
    </div>
  );
};

useZendeskWidget.propTypes = {
  key: PropTypes.string,
  options: PropTypes.object,
};
