import { ME_DATA_UPDATE } from "../../constants/action-types";
import { updateProfile } from "../../services/http_services";

export function updateMe(data) {
  const update_me = async () => {
    try {
      const profile = await updateProfile(data);
      return {
        data: profile,
      };
    } catch (e) {
      throw e;
    }
  };
  return {
    type: ME_DATA_UPDATE,
    payload: {
      promise: update_me(),
    },
  };
}
