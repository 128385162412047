import axios from "axios";
import { ENV } from "../utils";

const headers = {
  Accept: "application/vnd.api+json",
  "Content-Type": "application/vnd.api+json",
  ...(ENV.ORIGIN
    ? {
      "Origin": ENV.ORIGIN,
    } : {}),
  ...(ENV.X_SOURCE_ORIGIN
    ? {
        "X-Source-Origin": ENV.X_SOURCE_ORIGIN,
        //Temporary for development - https://test.ticketfairy.com
        Authorization: "Basic dGVzdDp0dGY=",
      }
    : {}),
};

const clientHeaders = {
  Accept: "application/vnd.api+json",
  "Content-Type": "application/vnd.api+json",
  ...(ENV.X_SOURCE_ORIGIN
    ? {
        "X-Source-Origin": ENV.X_SOURCE_ORIGIN,
        //Temporary for development - https://test.ticketfairy.com
        Authorization: "Basic dGVzdDp0dGY=",
      }
    : {}),
};

export const clientRequest = axios.create({
  baseURL: `${ENV.BASE_URL}`,
  withCredentials: true,
  headers: clientHeaders,
});

export const baseRequest = axios.create({
  baseURL: `${ENV.BASE_URL}`,
  withCredentials: true,
  headers,
});
