const isWindowDefined = typeof window !== "undefined";
var PIXEL_RATIO = false;
if (isWindowDefined) {
  PIXEL_RATIO =
    window.devicePixelRatio ||
    window.screen.deviceXDPI / window.screen.logicalXDPI ||
    1;
}

module.exports = PIXEL_RATIO;
