import React, { PureComponent } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { getDashboardItems } from "./dashboardItems";
import ModalHeader from "../../components/modals/ModalHeader";

class DashboardMenu extends PureComponent {
  constructor(props) {
    super(props);
    const { me: { rnRoles = [] } = {} } = props;
    this.dashboardItems = getDashboardItems(rnRoles);
  }
  render() {
    return (
      <div className="modal-container">
        <ModalHeader title={"Dashboard"} />
        <div className="modal-content pb-25p">
          {_.map(_.chunk(this.dashboardItems, 2), (pair, i) => (
            <div className="modal-list-pair-container" key={i}>
              {_.map(pair, (titleItem) => (
                <ul
                  key={titleItem.key}
                  className={`modal-list ${titleItem.className || ""}`}
                >
                  <p className="modal-list-header">
                    {titleItem.label.toUpperCase()}
                  </p>
                  {_.map(titleItem.items, (item) => {
                    const ItemComponent = item.component;
                    return (
                      <li
                        key={item.key}
                        className={`modal-list_item ${item.className || ""}`}
                      >
                        {ItemComponent ? <ItemComponent /> : item.label}
                      </li>
                    );
                  })}
                </ul>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.auth.me.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const _DashboardMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardMenu);

export { _DashboardMenu as DashboardMenu };
