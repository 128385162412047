var HIDDEN_PROPERTY_NAME = (function () {
  const isDocumentDefined = typeof document !== "undefined";
  if (!isDocumentDefined) {
    return false;
  }
  var value = undefined;
  var props = {
    hidden: "hidden",
    mozHidden: "mozHidden",
    msHidden: "msHidden",
    webkitHidden: "webkitHidden",
  };
  for (var p in props) {
    if (typeof document[p] !== "undefined") {
      value = props[p];
      break;
    }
  }
  return value;
})();

module.exports = HIDDEN_PROPERTY_NAME;
