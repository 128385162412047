import React, { Component } from "react";
import PasswordIcon from "@assets/svg/svgui/password-icon.svg";
import PasswordIconHide from "@assets/svg/svgui/password-icon-hide.svg";

class InputField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
    };
  }

  handleChange = (e) => {
    const { handleChange } = this.props;
    const fieldKey = e.target.name;
    const value = e.target.value;
    handleChange(fieldKey, value);
  };

  onShowPassword = (isShowPassword = !this.state.isShowPassword) => {
    this.setState(() => ({ isShowPassword }));
  };

  render() {
    const { isShowPassword } = this.state;
    const {
      label,
      value = "",
      initialValue = "",
      touched = false,
      required = false,
      disabled = false,
      placeholder = "",
      name,
      minLength = null,
      maxLength = null,
      type = "text",
      inputContainerClass,
      helperText,
      prefix,
      prefixWidth = "auto",
      labelAnimation = true,
      error = false,
    } = this.props;

    const hasPrefix = prefix ? "has-prefix" : "";
    const hasValue = !!value || (initialValue && !touched);

    return (
      <div
        className={`input-container ${inputContainerClass || ""} ${hasPrefix}`}
      >
        <label
          htmlFor={name}
          className={`input-label ${
            !labelAnimation || hasValue ? "has-value" : ""
          }`}
          onClick={this.handleClick}
        >
          {label}
        </label>
        {prefix && (
          <span className="input-field-prefix" style={{ width: prefixWidth }}>
            {prefix}
          </span>
        )}
        <input
          className={`input-field ${error ? "error-input" : ""} ${hasPrefix}`}
          name={name}
          type={isShowPassword ? "text" : type}
          value={initialValue && !touched ? initialValue : value}
          disabled={disabled}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          onChange={this.handleChange}
        />
        {helperText && (
          <label className="input-field-helper-text">{helperText}</label>
        )}
        {error && (
          <label className="input-field-helper-text-error">{error}</label>
        )}
        {type === "password" && value && (
          <span
            className="input-field-password-icon"
            onClick={() => this.onShowPassword()}
          >
            {isShowPassword ? (
              <div className="SVGInline">
                <PasswordIcon
                  className="SVGInline-svg"
                  style={{ width: 15, height: 15, fill: "#808080" }}
                />
              </div>
            ) : (
              <div className="SVGInline">
                <PasswordIconHide
                  className="SVGInline-svg"
                  style={{ width: 15, height: 15, fill: "#808080" }}
                />
              </div>
            )}
          </span>
        )}
      </div>
    );
  }
}

export default InputField;
